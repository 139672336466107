import React, { useState } from "react";
import { Auth } from "../dao/auth/auth";
export const firebaseAuth = React.createContext();

const AuthProvider = (props) => {
    const [user, setUser] = useState({ email: "", password: "" });
    const [uid, setUserId] = useState("");
    const [errors, setErrors] = useState([]);
    const [token, setToken] = useState(null);
    const handleSignup = (user, afterSignup) => {
        Auth.signup(
            user.email,
            user.password,
            setErrors,
            setToken, user,
            function(_uid, user, status) {
                afterSignup(_uid, user, status);
                setUserId(_uid);
            }
        );
    };
    const handleSignin = (user, afterSignIn) => {
        return Auth.signin(user.email, user.password, setErrors, setToken, function(_uid, status) {
            afterSignIn(_uid, user, status);
            setUserId(_uid);
        });
    };
    const handleSignout = () => {
        Auth.signout(setErrors, setToken);
    };
    return ( <
        firebaseAuth.Provider value = {
            {
                handleSignin,
                handleSignup,
                handleSignout,
                user,
                setUser,
                errors,
                token,
                setErrors,
                setToken,
                uid,
            }
        } > { " " } { props.children } { " " } <
        /firebaseAuth.Provider>
    );
};
export default AuthProvider;