import firebase from "../../config/firebase/firebase-config";

export const Auth = {
    // firebase helper methods go here...
    signup: (email, password, setErrors, setToken, user, onSignUp) => {
        var uid = "";
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((res) => {
                const token = Object.entries(res.user)[5][1].b;
                localStorage.setItem("token", token);
                setToken(window.localStorage.token);
                localStorage.setItem("user", JSON.stringify(res.user));

                uid = res.user.uid;
                // setUserId(uid)
                console.log("this is signUP here ", JSON.stringify(user));
                console.log("this is signUP here ", JSON.stringify(res));
                onSignUp(uid, user, "success");
            })
            .catch((err) => {
                setErrors((prev) => [...prev, err.message]);
                console.log("login failed login ");
                onSignUp(uid, user, "fail");
            });
    },
    signin: (email, password, setErrors, setToken, onSignIn) => {
        var uid = "";
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            //everything is almost exactly the same as the function above
            .then(async(res) => {
                const token = await Object.entries(res.user)[5][1].b;
                //set token to localStorage
                localStorage.setItem("token", token);
                localStorage.setItem("user", JSON.stringify(res.user));
                setToken(window.localStorage.token);
                uid = res.user.uid;
                console.log("successfully login  ");
                onSignIn(uid, "success");
            })
            .catch((err) => {
                setErrors((prev) => [...prev, err.message]);
                console.log("login failed login ");
                onSignIn(null, "fail");
            });
    },
    signout: (setErrors, setToken) => {
        // signOut is a no argument function
        firebase
            .auth()
            .signOut()
            .then((res) => {
                //remove the token
                localStorage.removeItem("token");
                //set the token back to original state
                setToken(null);
                console.log("here is token is going to be null in Auth");
            })
            .catch((err) => {
                //there shouldn't every be an error from firebase but just in case
                setErrors((prev) => [...prev, err.message]);
                //whether firebase does the trick or not i want my user to do there thing.
                localStorage.removeItem("token");
                setToken(null);
                console.error(err.message);
            });
    },
    resetpassword: (email) => {
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(function() {
                console.log("email send successfully ");
            })
            .catch(function(error) {
                console.log("email send falied due to network Error ");
            });
    },
};