import React, { useContext, useState } from "react";

import { Spinner, Form, Row, Col, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { firebaseAuth } from "../../provider/authprovider";
import Colors from "../../config/color.js";
import "./assets/login.css";
import LoginActions from "./actions/form-actions";
import { Firestore } from "../../dao/firebase/firebase-firestore";
import { Auth } from "../../dao/auth/auth";
import sendEmail from "../../helpers/email/email";
import registrationTemplate from "../../templates/registration";
import CountryCodes from "./components/country-codes";
import RegistrationForm from "./components/registration"; 
import LoginForm from "./components/login";
import ResetPasswordForm from "./components/reset";
var temp = {}; 
export const Login = () => {
  const { 
    errors,
    setErrors,
    token,
  } = useContext(firebaseAuth); 
  const [showLogin, setShowLogin] = useState(true);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  
  const handleReset = (e) => {
    setShowLogin(false) 
    setShowRegistration(false) 
    setShowResetPassword(true)
  }; 

  if (errors.length > 0) {
    setTimeout(() => {
      setErrors([]);
    }, 2500);
  } 
  return (
    <Row
      className={"d-flex flex-column justify-content-center align-items-center"}
      style={{
        paddingBottom: "319px",
        overflow: "hidden",
        // backgroundColor: (token===null?"AQUA":""),
        paddingTop: token === null ? "20px" : "",
        // backgroundColor: "AQUA",
        // padding: "100px",
      }}
    >
      {token === null ? "" : <Redirect to="/" />}
      {/* convert this into col remove width of 420 below  uncomment padding above*/}

      <div
        // md={5}
        // sm={12}
        style={{ backgroundColor: Colors.primary, width: "420px" }}
        className={"auth-box"}
      >
        <div className="d-flex">
          <button
            className="auth-header-btn-left  "
            style={{
              backgroundColor: Colors.primary,
              borderTop: `2px solid ${Colors.primary}`,
              borderLeft: `2px solid ${Colors.primary}`,
              borderBottom: `2px solid ${Colors.primary}`,
              flex: "1",
            }}
            onClick={() => (
              setShowLogin(true),
              setShowRegistration(false),
              setShowResetPassword(false)
            )}
          >
            Login
          </button>{" "}
          <button
            className="auth-header-btn-right  "
            style={{
              backgroundColor: Colors.primary,
              borderTop: `2px solid ${Colors.primary}`,
              borderRight: `2px solid ${Colors.primary}`,
              borderBottom: `2px solid ${Colors.primary}`,
              flex: "1",
            }}
            onClick={() => (
              setShowLogin(false),
              setShowRegistration(true),
              setShowResetPassword(false)
            )}
          >
            Register
          </button>
        </div>
        {showLogin ? (
          <LoginForm onRest={handleReset}  />
        ) : showRegistration ? (
          <RegistrationForm   />
        ) : showResetPassword ? (
          <ResetPasswordForm />
        ) : (
          ""
        )}
      </div>
    </Row>
  );
};
export default Login;
