import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

const Terms = (props) => {
  return (
    <Container>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p>
            These campaign draw terms and conditions (“Draw Terms”) govern the
            terms and conditions applicable to Ebgiwin Enterprises LLC
            (“Ebgiwin”, “We”, “Us”, “Our”) campaign draws (“Campaign Draws”)
            that relate to Our website (“Site”) and Our mobile app (“App”)
            (together the “Platform”). Any party using the Platform and/or
            participating in a Campaign Draw (“User”, “You”, “Your”) agrees to
            be bound by these Draw Terms, as well as the user agreement
            published on the Site (“User Agreement”). If You do not agree to
            these Draw Terms, or any of its terms (including any amendments),
            then You should immediately cease using the Platform, and not enter
            any Campaign Draw. These Draw Terms are effective as of 27 November
            2017. We may amend these Draw Terms at any point in time without
            notice and the amendments will take effect once they have been
            displayed on the Platform. You acknowledge and agree that it is Your
            responsibility to review these Draw Terms periodically to
            familiarize Yourself with any modifications. Your continued use of
            the Platform and/or entry into Campaign Draws following any
            amendments shall be construed as acceptance of those amendments.
          </p>
          <Container>
            <ul>
              <li>
                Campaign Draws will be approved by Department of economic
                development in Abu Dhabi and are in accordance with the
                Commercial Permit Guidebook (as such may be amended from time to
                time). A valid permit number will be issued for each respective
                campaign. Where Campaigns are being run from any other
                jurisdiction We operate from, Ebgiwin will obtain the necessary
                regulatory requirements and permits as stipulated by the law in
                such jurisdiction.
              </li>

              <li>
                Only Users with a valid membership to the Platform
                (“PlatformMembers”) that satisfies all the terms of the User
                Agreement are eligible to enter into our Campaign Draws.
              </li>

              <li>
                Each product purchased from a campaign by a Platform Member is
                awarded a single complimentary ticket per product to a specified
                Campaign Draw.
              </li>
              <li>
                {" "}
                A Platform Member will have the option to either collect the
                purchased product or have such contributed to a charity of Our
                choice. If a Platform Member opts to so contribute their
                purchased product to charity, Ebgiwin will double their ticket
                entry into the specified Campaign Draws.
              </li>
              <li>
                If a Platform Member opts NOT to so contribute the purchased
                product to charity and instead wishes to collect the purchased
                product, they must visit Our premises within 15 calendar days
                and present valid identification as may be required in Our sole
                discretion. Ebgiwin may, at its sole discretion, allow an
                individual who has been given Power of Attorney (POA) by the
                Platform Member to collect the Product having presented the
                necessary identification, Ebgiwin will evaluate such instances
                on a case by case basis and cannot guarantee that an individual
                with POA will be allowed to collect the Product on behalf of the
                account holder. Failure to collect a purchased product within
                this time frame will result in such being donated to charity.
              </li>
              <li>
                Complimentary ticket numbers are computer generated and
                sequential to the order in which the products they are
                associated with are purchased. Platform Members cannot choose
                ticket numbers as they are generated only upon checkout and
                completion of purchase of the associated products.
              </li>

              <li>
                Product and the Complimentary tickets awarded to Platform
                Members are neither refundable nor transferable and are owned
                solely and exclusively by the Platform Member who they have been
                awarded to.
              </li>
              <li>
                Campaign Draws occur on every Monday and Thursday of the week
                for those Campaigns that have sold out a minimum of 24 hours
                prior to every Monday and Thursday of every week. If the Monday
                or Thursday falls on a day that is not a business day in the
                United Arab Emirates, the draw shall take place on the first
                business day following. Campaign Draws shall occur in the
                presence of a regulatory representative, unless the regulator
                approves otherwise, and as such are not subject to negotiation,
                interference, challenge, or argument by a Platform Member. The
                draw date will be subject to the representative’s availability.
              </li>

              <li>
                Ebgiwin has the right to close a Campaign Draw at any time. In
                the event that a campaign is oversubscribed for any reason, We
                will forthwith reimburse to the oversubscribed Platform Members
                the payment they have made for the associated produced based on
                the sequential ticket numbers representing the oversubscribed
                number of tickets.
              </li>
              <li>
                Winners of Campaign Draws (“Winners”) are selected by the
                regulatory representative and will be notified on the day the
                draw occurs. The names of Winners may also be announced in the
                local and regional press as well as on the Platform and our
                associated social (online) media profiles, unless You
                specifically advise us otherwise in writing.
              </li>
              <li>
                All Platform Members consent to the use of their name in the
                event they are the Winner of any of the Campaign Draws. This
                includes but is not limited to use of their name in the local
                and regional press, on the Platform and on any social (online)
                media profile associated with Us. 
              </li>
              <li>
                Post notifying the Winner of their win on the day of the draw,
                an email will be sent to the registered email on the Platform
                detailing the next steps and how the Winner can claim the prize
                (“Prize”).
              </li>
              <li>
                All Platform Members consent that We have the right to use any
                and all digital records made by Us including, but not limited
                to, photographs, videos and call recordings of, the Prize, the
                Campaign Draw, the winning phone call and the collection of the
                Prize, on the Platform and on any social (online) media profile
                associated with Us. These digital records may include imagery
                and/or voice recordings of the Winner and/or their name.
              </li>
              <li>
                In the event the Winner is based in the United Arab Emirates,
                then he/she needs to collect the Prize from Our premises within
                60 calendar days and present valid identification as may be
                required in Our sole discretion. Ebgiwin may, at its sole
                discretion, allow an individual who has been given Power of
                Attorney (POA) by the account holder to collect the Prize having
                presented the necessary identification, Ebgiwin will evaluate
                such instances on a case by case basis and cannot guarantee that
                an individual with POA will be allowed to collect the Prize on
                behalf of the account holder. Failure to collect the Prize
                within this time period WILL result in Us transferring the Prize
                to the regulator whereafter Ebgiwin will have no further
                responsibility with respect to the Prize. Post transfer, the
                Winner must liaise with the regulator whereupon he/she will be
                subject to the regulator’s own terms and conditions.
              </li>
              <li>
                In the event a Winner does not reside in the United Arab
                Emirates, We will either courier the Prize to the Winner by air
                or land in the case of smaller items (as We may determine in Our
                sole discretion), or ship the Prize to the Winner’s country of
                residence (nearest seaport as selected by Us in Our sole
                discretion) free of charge, and We will insure shipment of the
                Prize until delivery thereof at the port. Collection from the
                port and clearance through the port and customs shall be the
                responsibility, and at the cost, of the Winner. Accordingly, all
                local taxes, custom duties, and any other form of expense beyond
                shipping and delivery at the port will be paid by the Winner.
              </li>
              <li>
                Once a Prize is handed to/collected by the courier/handler for
                international delivery, risk and responsibility will transfer to
                the Winner and We no longer hold claim nor responsibility for
                any damage/injury incurred in transit, or upon delivery. 
              </li>
              <li>
                All Prizes represented on our Platform, will be awarded as
                displayed. Minor changes, including but not limited to color,
                may occur, however, no major changes, including but not limited
                to, specification, brand or value, will occur.
              </li>
              <li>
                Ebgiwin is not responsible for any bank charges, including but
                not limited to processing fees from Your local bank.
              </li>
              <li>
                By entering the Campaign Draw, Winners confirm their agreement
                to take part in any associated publicity.
              </li>
              <li>
                If any Winner is subsequently found ineligible to participate in
                the Campaign Draw, We may at Our sole discretion forfeit or
                reclaim the Prize or dispose of the same in such manner and to
                such person as We deem fit. All such decisions will be in line
                with DE regulations as well as UAE law.
              </li>
              <li>
                Winners are responsible for any and all tax liability where
                applicable.
              </li>
              <li>
                We will not be responsible for arranging insurance, including
                travel insurance, medical insurance, transport expenses, meal
                expenses, or any expenses of a personal nature, in the
                collection of the Prize unless otherwise stated.
              </li>
              <li>
                We will not be responsible for the inability of any Winner to
                utilize the Prize, for any reason whatsoever, including but not
                limited to non-issuance of a visa.
              </li>
              <li>
                We will not be responsible for claims, damages, or expenses of
                any nature whatsoever for any loss, illness, bodily injury,
                including death, of or to any Winner and/or any third party
                during and/or in course of usage of any Prize or due to any
                defects in any Prize.
              </li>
            </ul>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
