import React, { useEffect, useContext } from "react";
import { useState } from "react";
import Context from "../../../context/Context";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { cartData } from "../../../helpers/data/cart";
import sendEmail from "../../../helpers/email/email";
import generateHash from "../../../helpers/hash";
import PaymentCard from "./components/payment-card";
import { Redirect, Link } from "react-router-dom";
import GooglePay from "../../winner/winner";
import "./stylesheet/checkout.css";
import { Auth } from "../../../dao/auth/auth";
import ApplePay from "../../winner";
import LoginActions from "../../auth/actions/form-actions";
import campaignsEmailTemplate from "../../../templates/campaigns";
import Axios from "axios";
import { firebaseAuth } from "../../../provider/authprovider";
import { parse } from "query-string";
import Email from "../../../templates/campaigns"; 
import Actions from "../actions/form-actions";
const Checkout = () => {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({});
  const [shoppingDone, setShoppingDone] = useState(false);
  const [redirectToIndex, setRedirectToIndex] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { token } = useContext(firebaseAuth);
  const { setHideCurrencyBar, currency, setCount } = useContext(Context);
  const [priceState, setPriceState] = useState(1);

  setHideCurrencyBar(false);
  const calculateTotal = () => {
    let tempTotal = 0;
    cartData().map((item, index) => {
      tempTotal += parseFloat(item.product.price) * parseInt(item.count);
    });
    setTotal(tempTotal.toFixed(2));
  };

  useEffect(() => {
    calculateTotal();
    
     Actions.data("currency", function(document){
      var obj = {};
      for (var key in document) {
        obj =  document[key]  ; 
      }   
      if (currency === "AED") {
        setPriceState((parseFloat(obj.AED)).toFixed(2));
      } else if (currency === "BHD") {
        setPriceState((parseFloat(obj.BHD)).toFixed(2));
      } else if (currency === "INR") {
        setPriceState((parseFloat(obj.INR)).toFixed(2));
      } else if (currency === "KWD") {
        setPriceState((parseFloat(obj.KWD)).toFixed(2));
      } else if (currency === "OMR") {
        setPriceState((parseFloat(obj.OMR)).toFixed(2));
      } else if (currency === "SAR") {
        setPriceState((parseFloat(obj.SAR)).toFixed(2));
      }
    }) 
 
  }, []);

  var temp = {};
  const handleChange = (e) => {
    temp[e.target.name] = e.target.value;
  };
  const handlePayEasypaisa = () => {
    let tempData = {};
    if (LoginActions.validate(temp) && Object.keys(temp).length > 1) {
      temp["status"] = "none";
      var tempCampaigns = {};
      const user = JSON.parse(localStorage.getItem("user"));
      cartData().map((item, index) => {
        tempData[item.id] = {
          campaign: `campaigns/${item.id}`,
          coupons: item.product.coupons,
          purchaseCount: item.count,
        };
        if (
          parseInt(item.threshold) ===
          parseInt(item.soldCount) + parseInt(item.tempCount)
        ) {
          Firestore.saveWithId("campaigns", item.id, {
            soldCount: item.threshold,
            status: "completed",
          });
        } else {
          Firestore.saveWithId("campaigns", item.id, {
            soldCount: item.newSoldCount.toString(),
          });
        }
        Firestore.saveWithId("coupons", item.id, {
          [user.uid]: {
            campaignId: item.id,
            coupons: item.product.coupons,
            contactNumber: user.mobileNo,
            firstName: user.firstName,
            lastName: user.lastName,
            userId: user.uid,
          },
        });
        Firestore.saveWithId("campaignTransactions", item.id, {
          [temp.transctionId]: {
            transctionId: temp.transctionId,
            description: temp.description,
            status: temp.status,
            userId: user.uid,
          },
        });
        delete item["tempCount"];
        delete item["newSoldCount"];
        // delete item["count"];
        tempCampaigns[item.id] = item;
      });
      Firestore.saveWithId("userTransactions", user.uid, {
        [temp.transctionId]: {
          transctionId: temp.transctionId,
          description: temp.description,
          status: temp.status,
          campaigns: tempCampaigns,
          grandTotal: total,
        },
      });
      Firestore.saveWithId("userCampaigns", user.uid, tempData);
      localStorage.removeItem("cart");
      setCount(0);
      setShoppingDone(true);
      setTimeout(() => {
        setShoppingDone(false);
        setRedirectToIndex(true);
      }, 1500);
    } else {
      LoginActions.showAlert(
        "Please fill the fields",
        setAlertMsg,
        setShowAlert
      );
    }
  };

  const handleCancelCheckout = () => {
    localStorage.removeItem("cart");
    setCount(0);
  };


  

  return (
    <>
      {token === null ? <Redirect to="/"></Redirect> : ""}
      {shoppingDone ? (
        <div className="thankyou-container">
          <div className="thankyou-box">
            <h1 className="thankyou-text">Thank You For Shopping </h1>
          </div>
        </div>
      ) : (
        ""
      )}
      {redirectToIndex ? <Redirect to="/" /> : ""}
      {/* Breadcrumb Section Begin {*/}
      <section className="breadcrumb-option">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb__text">
                <h4>Check Out</h4>
                <div className="breadcrumb__links">
                  <Link to="/">Home</Link>
                  <span
                    onClick={() => {
                      Auth.changePassword("", "");
                    }}
                  >
                    Shop
                  </span>
                  <span>Check Out</span>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb Section End {*/}
      {/* Checkout Section Begin {*/}
      <div className="checkout spad">
        <div className="container">
          <div className="checkout__form">
            <div className="row">
              <div className="col-lg-8 col-md-6">
                <div className="row">
                  {/* <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <GooglePay total={total} currencyCode={"USD"} countryCode={"US"}  />
                    <ApplePay/>
                  </div> */}
                  {/* Easy paisa  */}

                  {showAlert ? (
                    <div
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        width: "100%",
                      }}
                      className="alert alert-danger d-flex align-self-center justify-content-center  "
                      role="alert"
                    >
                      <strong>{alertMsg} </strong>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <Row>
                      <Col className="breadcrumb__text mb-5" md={12}>
                        <h4>Pay With Easypaisa (+92346-8932-35553 ) </h4>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="transctionId"
                            className="auth-input"
                            onChange={handleChange}
                            placeholder="Transaction ID "
                            style={{
                              marginTop: "unset",
                              borderRadius: "unset",
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="description"
                            className="auth-input"
                            onChange={handleChange}
                            placeholder="Transaction Description "
                            style={{
                              marginTop: "unset",
                              borderRadius: "unset",
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        className="pull-right d-flex justify-content-center"
                        md={12}
                      >
                        <button
                          style={{ width: "30%" }}
                          onClick={() => {
                              handlePayEasypaisa();
                            Email.getCampaignsEmailTemplate((tempe)=>{
                              // sendEmail(
                              //   JSON.parse(localStorage.getItem("user")).email,
                              //   "Transaction made successfuly",
                              //   tempe
                              // );
                              // console.log("this is email here ", tempe)
                            });
                          }}
                          className="btn btn-primary mt-4 btn-sm auth-verify-btn"
                        >
                          Send
                        </button>
                        <button
                          style={{ width: "30%" }}
                          onClick={() => {
                            handleCancelCheckout();
                          }}
                          className="btn btn-primary mt-4 btn-sm auth-verify-btn cancel-btn-link"
                        >
                          <Link className={"cancel-btn-link"} to={"/"}>
                            {" "}
                            Cancel Checkout{" "}
                          </Link>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* Easy paisa ended */}

                {/* <PaymentCard
                  shoppingDone={function () {
                    setShoppingDone(true);
                    setTimeout(() => {
                      setShoppingDone(false);
                      setRedirectToIndex(true);
                    }, 1500);
                  }}
                /> */}

                {/* <div className={"mt-4 mb-4"}>
                  <div className="checkout__input__checkbox">
                    <label htmlFor="card">
                      Card
                      <input type="checkbox" id="card" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="checkout__input__checkbox">
                    <label htmlFor="paypal">
                      Paypal
                      <input type="checkbox" id="paypal" />
                      <span className="checkmark"></span>
                    </label>
                  </div>   
                  <div className="checkout__input__checkbox">
                    <label htmlFor="stripe">
                      Stripe
                      <input type="checkbox" id="stripe" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="checkout__order">
                  <h4 className="order__title">Your order</h4>
                  <div className="checkout__order__products">
                    Product <span>Total</span>
                  </div>
                  <ul className="checkout__total__products">
                    {cartData().map((item, index) => (
                      <li key={generateHash()}>
                        {index + 1} {item.title}{" "}
                        <span>
                          {parseFloat(item.product.price) *
                            parseInt(item.count) * parseFloat(priceState)}{" "}
                          {currency}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <ul className="checkout__total__all">
                    <li>
                      Subtotal{" "}
                      <span>
                        {" "}
                        {total *   parseFloat(priceState)} {currency}
                      </span>
                    </li>
                    <li>
                      Total{" "}
                      <span>
                        {" "}
                        {total * parseFloat(priceState)} {currency}
                      </span>
                    </li>
                  </ul>
                  <p>
                    you are valued customer lorem eiusmod tempor incididunt ut
                    labore et dolore magna aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Checkout Section End {*/}
    </>
  );
};

export default Checkout;
