import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

const FAQ = (props) => {
  return (
    <Container className={"pt-4 pb-4"}>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <Container>
            <h3 className={"mt-4 mb-4"}>Frequently asked questions</h3>

            <h3 className={"m-4"}>About Us</h3>

            <ul>
              <li>
                <b>What Is EBIGWIN? </b>
                <p>
                  Ebgiwin is a stationery and garment store with a twist. For
                  every purchase made, Ebgiwin gives its customers a
                  complimentary Prize Draw ticket where they can win a luxury
                  prize.
                </p>
              </li>
              <li>
                <b>How can I access Ebgiwin.com? </b>
                <p>
                  Ebgiwin is available on desktop, smart phone, tablet and via
                  our interactive kiosks.{" "}
                </p>
              </li>
              <li>
                <b>Is Ebgiwin available on Android and IOS? </b>
                <p>
                  Yes. Ebgiwin is available on both App Store and Google Play
                  Store.{" "}
                </p>
              </li>
              <li>
                <b>How to find us?</b>
                <p>
                  Ebgiwin HQ is situated in Abu Dhabi United Arab Emirates.{" "}
                </p>
              </li>
              <li>
                <b> What countries does Ebgiwin operate in? </b>
                <p>
                  Ebgiwin is accessible globally. For full details on
                  product/prize collection for international users, please see
                  our ‘Draw Terms & Conditions’.
                </p>
              </li>
            </ul>

            <h3 className={"m-4"}>Account</h3>

            <ul>
              <li>
                <b> Why should I register an account with Ebgiwin? </b>
                <p>
                  To complete a purchase with Ebgiwin you must have a registered
                  account. This is for your security and protection.
                </p>
              </li>

              <li>
                <b> How do I set up my Ebgiwin account? </b>
                <p>
                  To create an account simply click ‘Login/Register’ in the top
                  right hand corner of your screen and enter your details in the
                  fields highlighted.
                </p>
              </li>

              <li>
                <b> How do I edit my Ebgiwin account? </b>
                <p>
                  To edit your account, click on your name/photo icon located in
                  the main menu. Tap profile section to edit and click 'Save
                  changes' to complete.
                </p>
              </li>

              <li>
                <b>
                  Can I use someone else’s credit card in my Ebgiwin account to
                  complete a purchase?
                </b>
                <p>
                  If you have the card holder’s permission, you can use/save any
                  credit card to complete your purchase.
                </p>
              </li>

              <li>
                <b> How do I ‘Logout’ of my Ebgiwin account? </b>
                <p>
                  To ‘Logout’ simply click on your name/photo in the top right
                  hand corner and select ‘Logout’ from the drop-down menu.
                </p>
              </li>

              <li>
                <b> Will I receive ‘push notifications’ if I ‘Logout’? </b>
                <p>
                  You will receive only general notifications when logged out,
                  to receive account related information you must ‘Login’. 
                </p>
              </li>
              <li>
                <b>I can’t remember my Ebgiwin account password! </b>
                <p>
                  At ‘Login’ select ‘Forgot Password’ and follow the on-screen
                  instructions for password reset.
                </p>
              </li>

              <li>
                <b>I want to change my password! </b>
                <p>
                  To change your password, click on your name/photo in the top
                  right hand corner and select ‘My FAQ’. From here select the
                  ‘FAQ’ tab to change your password.
                </p>
              </li>

              <li>
                <b>
                  What Credit Cards does Ebgiwin accept?
                  <p>
                    Ebgiwin accepts all major credit/debits cards including (but
                    not limited to): Visa,MasterCard and Amex.
                  </p>
                </b>
              </li>

              <li>
                <b> How do I close my Ebgiwin account? </b>
                <p>
                  To close your account you must notify us in writing (from your
                  registered email account) at: support@Ebgiwin.com. Hereafter,
                  a dedicated member of our support team will assist you within
                  48 hrs.
                </p>
              </li>

              <li>
                <b>
                  Why am I receiving ‘push notifications’/emails from Ebgiwin?
                </b>
                <p>
                  Ebgiwin is not in the business of spamming or bombarding its
                  users with irrelevant ‘push notifications’/emails. Any notices
                  sent by Ebgiwin have been deemed relevant to your user
                  experience.
                </p>
              </li>
            </ul>

            <h3 className={"m-4"}>Campaigns</h3>
            <ul>
              <li>
                <b>How frequently do new Ebgiwin campaigns emerge? </b>
                <p>
                  New campaigns are created instantly at the close of an
                  existing campaign. There is no set time limit per campaign,
                  however, you can expect new campaigns to emerge frequently.
                </p>
              </li>

              <li>
                <b>Will the product/prize categories expand? </b>
                <p>
                  New products and prizes are on their way, stay tuned for some
                  exciting campaigns.
                </p>
              </li>
            </ul>

            <h3 className={"m-4"}>Campaigns</h3>

            <ul>
              <li>
                <b>How frequently do new Ebgiwin campaigns emerge? </b>
                <p>
                  New campaigns are created instantly at the close of an
                  existing campaign. There is no set time limit per campaign,
                  however, you can expect new campaigns to emerge frequently.
                </p>
              </li>

              <li>
                <b>Will the product/prize categories expand?</b>
                <p>
                  New products and prizes are on their way, stay tuned for some
                  exciting campaigns.
                </p>
              </li>
            </ul>

            <h3 className={"m-4"}>Making a Purchase </h3>

            <ul>
              <li>
                <b>Are there any hidden charges I should be aware of? </b>
                <p>
                  There are no hidden charges on any Ebgiwin purchase. You
                  should however CHECK with your bank to see if they apply any
                  transaction/processing fees.
                </p>
              </li>
              <li>
                <b>Will I be charged VAT?</b>
                <p>
                  Yes! As of January 1st, 2018 all purchases will incur 5% VAT.
                </p>
              </li>
              <li>
                <b>What currencies can I use to purchase an Ebgiwin product?</b>
                <p>
                  Ebgiwin currently only accepts Dirhams and US Dollars. You can
                  make a purchase from Ebgiwin via any bank account with any
                  currency - however, Ebgiwin will not accept responsibility for
                  final billings which have been affected by exchange rates or
                  fees (hidden or otherwise) as imposed by your branch.
                </p>
              </li>
              <li>
                <b>Can I cancel/refund an Ebgiwin purchase order?</b>
                <p>
                  As per the ‘User Agreement’ all sales purchases are final and
                  no refunds are given under any circumstances.
                </p>
              </li>
            </ul>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
