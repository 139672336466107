import React, { useEffect, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Context from "../../../context/Context";
import generateHash, { generateCoupons } from "../../../helpers/hash";
import { Link } from "react-router-dom";
import { cartData } from "../../../helpers/data/cart";
import "./stylesheet/cart.css";
import { firebaseAuth } from "../../../provider/authprovider";
const Cart = () => {
  const [cartItemCount, setCartItemCount] = useState(0);
  const [coupons, setCoupons] = useState(0);
  const [_cartData, setCartData] = useState([]);
  const [price, setPrice] = useState(0); 
  const { token } = useContext(firebaseAuth);
  const { setHideCurrencyBar, setCount, currency, setChecked, checked } = useContext(Context); 
  setHideCurrencyBar(true);
  useEffect(function () {
    const tempCartData = cartData();
    let tempCount = tempCartData.length;
    let tempCouponCount = 0;
    let tempPrice = 0;
    setCartData(tempCartData);
    console.log(JSON.stringify(_cartData));
    tempCartData.map((item) => {
      tempCouponCount = tempCouponCount + item.product.coupons.length; 
      tempPrice = tempPrice + item.product.price * item.count;
    });
    setPrice(tempPrice.toFixed(3));
    setCartItemCount(tempCount);
    setCoupons(tempCouponCount);
  }, []);


const handleDonated=(e)=>{ 
    if (e.target.checked) {
      setCoupons(coupons * 2);
      setChecked(true);
      const tempCartData = _cartData;
      const tempCartObject = {};

      tempCartData.map((item, index) => {
        const newCoupons = generateCoupons(
          item.product.coupons.length
        ).concat(item.product.coupons);

        item.product.coupons = newCoupons;

        tempCartObject[item.id] = item;

        tempCartData[item.id] = item;
      });

      setCartData(tempCartData);
      localStorage.setItem(
        "cart",
        JSON.stringify(tempCartObject)
      );

      console.log(JSON.stringify(tempCartObject));
    } else {
      setCoupons(coupons / 2);
      setChecked(false);
      const tempCartData = _cartData;
      const tempCartObject = {};

      tempCartData.map((item, index) => {
        item.product.coupons.length =
          item.product.coupons.length / 2;

        tempCartObject[item.id] = item;

        tempCartData[item.id] = item;
      });

      setCartData(tempCartData);
      localStorage.setItem(
        "cart",
        JSON.stringify(tempCartObject)
      );

      console.log(JSON.stringify(tempCartObject));
    } 
}

  
  return (
    <>
      {/* Breadcrumb Section Begin  */}
      <section className="breadcrumb-option">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb__text">
                <h4>Shopping Cart</h4>
                <div className="breadcrumb__links">
                  <a href="./index.html">Home</a>
                  <a href="./shop.html">Shop</a>
                  <span>Shopping Cart</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb Section End  */}

      {/* Shopping Cart Section Begin  */}
      <section className="shopping-cart spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="shopping__cart__table">
                <table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Prize</th>
                      <th>Total</th>
                      <th> Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_cartData.map((item, index) => (
                      <tr key={generateHash()}>
                        <td className="product__cart__item">
                          <div className="product__cart__item__pic">
                            {/* <img style={{height: "200px", maxWidth:"200px"}} src={item.product.image} alt="" /> */}
                          </div>
                          <div className="product__cart__item__text">
                            <h6>{item.product.title}</h6>
                            <h5>
                              {" "}
                              {item.product.price} &nbsp;
                              {currency}{" "}
                            </h5>
                            {item.product.coupons.map((coupon) => (
                              <div key={generateHash()} className="coupon-code">
                                {/* <span>{coupon}</span> */}
                              </div>
                            ))}
                          </div>
                        </td>
                        <td className="product__cart__item">
                          <div className="product__cart__item__pic">
                            {/* <img style={{height: "200px", maxWidth:"200px"}} src={item.prize.image} alt="" /> */}
                          </div>
                          <div className="product__cart__item__text">
                            <h6>{item.prize.title}</h6>
                          </div>
                        </td>
                        <td className="cart__price">
                          {(
                            parseFloat(item.product.price) *
                            parseFloat(item.count)
                          ).toFixed(2)}
                          &nbsp;
                          {currency}
                        </td>
                        <td className="d-flex justify-content-center">
                          <span
                            className="cross-icon"
                            style={{
                              fontSize: "23px",
                              fontWeight: "900",
                              marginTop: "30px",
                              cursor: "pointer",
                            }}
                            onClick={function () {
                              const cart = JSON.parse(
                                localStorage.getItem("cart")
                              );
                              delete cart[item.id];
                              localStorage.setItem(
                                "cart",
                                JSON.stringify(cart)
                              );
                              setCount(
                                Object.keys(
                                  JSON.parse(localStorage.getItem("cart"))
                                ).length
                              );

                              const tempCartData = cartData();

                              setCartData(tempCartData);
                            }}
                          >
                            &#10006;
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cart__total">
                <h6>Cart total</h6>

                <ul>
                  <li></li>
                  {/* <li className="d-flex justify-content-between">
                    <div className="donate">
                      <h5>{"Donate to receive an additional entry !"}</h5>
                      <div>
                        <small>
                          {" "}
                          <p>
                            I agree to donate all purchased products to charity
                            as per the Draw Terms & Conditions
                          </p>{" "}
                        </small>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "10px" }}
                      className="checkbox switcher"
                    >
                      <label for="test1">
                        <input
                          type="checkbox"
                          checked={checked}
                          onChange={handleDonated}
                          id="test1"
                          value=""
                        />
                        <span>
                          <small></small>
                        </span>
                      </label>
                    </div>
                    {/* <div style={{marginTop:"10px"}}>
                      <Form.Check
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCoupons(coupons * 2);

                            const tempCartData = _cartData;
                            const tempCartObject = {};

                            tempCartData.map((item, index) => {
                              const newCoupons = generateCoupons(
                                item.product.coupons.length
                              ).concat(item.product.coupons);

                              item.product.coupons = newCoupons;

                              tempCartObject[item.id] = item;

                              tempCartData[item.id] = item;
                            });

                            setCartData(tempCartData);
                            localStorage.setItem(
                              "cart",
                              JSON.stringify(tempCartObject)
                            );

                            console.log(JSON.stringify(tempCartObject));
                          } else {
                            setCoupons(coupons / 2);
                            const tempCartData = _cartData;
                            const tempCartObject = {};

                            tempCartData.map((item, index) => {
                              item.product.coupons.length =
                                item.product.coupons.length / 2;

                              tempCartObject[item.id] = item;

                              tempCartData[item.id] = item;
                            });

                            setCartData(tempCartData);
                            localStorage.setItem(
                              "cart",
                              JSON.stringify(tempCartObject)
                            );

                            console.log(JSON.stringify(tempCartObject));
                          }
                        }}
                        type="switch"
                        id="custom-switch"
                        size="xl"
                      />
                    </div>  
                  </li>
                */}
               
                  <li>
                    Total Product <span>{cartItemCount}</span>
                  </li>
                  {/* <li>
                    Total Coupon <span>{coupons}</span>
                  </li> */}
                  <li>
                    Grand Total{" "}
                    <span>
                      {" "}
                      {price} &nbsp;
                      {currency}
                    </span>
                  </li>
                </ul>
                {token === null ? (
                  <Link className={"btn btn-sm btn-info"} to={"/login"}>
                    {"Please Login Before checkout"}
                  </Link>
                ) : (
                  <Link className={"btn btn-sm btn-info"} to={"/checkout"}>
                    {"Proceed to checkout"}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
