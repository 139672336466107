import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

const UserAgreement = (props) => {
  return (
    <Container className={"mb-4"}>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <h3 className={"m-4"}> User Agreement </h3>
          <p>
            The below preamble and general terms and conditions (“General
            Terms”), along with the privacy policy (“Privacy Policy”), and draw
            terms and conditions (“Draw Terms”) (together the “User Agreement”)
            constitute the contract between You and Ebgiwin Enterprises LLC
            (“Ebgiwin”, “We”, “Us”, “Our”) relating to the use of Our website
            (“Site”) and Our mobile app (“App”) (together the “Platform”) and
            Your participation in Our campaign draws (“Campaign Draws”).
          </p>
          <Container>
            <ol>
              <li>
                Preamble
                <ol className={"m-3"}>
                  <li>
                    Any party using the Platform and/or participating in a
                    Campaign Draw (“User”, “You”, “Your”), agrees to be bound by
                    this User Agreement. If You do not agree to this User
                    Agreement, or any of its terms (including any amendments),
                    then You should immediately cease using the Platform and not
                    enter any Campaign Draw.
                  </li>
                  <li>
                    This User Agreement is in effect as of 27 November 2017. We
                    may amend this User Agreement at any point in time without
                    notice and the amendments will take effect once they have
                    been displayed on the Platform. You acknowledge and agree
                    that it is Your responsibility to review this User Agreement
                    periodically to familiarize Yourself with any modifications.
                    Your continued use of the Platform or any of Our products,
                    offerings and services, including, but not limited to,
                    Campaign Draws (“Services”) following any amendments shall
                    be construed as acceptance of those amendments.
                  </li>
                </ol>
              </li>

              <li>
                General Terms and Conditions
                <ol className={"m-3"}>
                  <li>
                    Eligibility for Membership
                    <ol className={"m-3"}>
                      <li>
                        It is imperative for Ebgiwin to ensure that its members
                        are able to enter into legally binding contracts and
                        that minors do not purchase, use, or have access to
                        unsuitable content. Membership and/or use of the
                        Platform is not available to persons under the age of 18
                        years old. By using the Platform, You represent and
                        warrant that You are 18 years of age or over and that
                        all information You submit is accurate and truthful when
                        registering any information with the Platform or
                        providing any information to Us.
                      </li>
                      <li>
                        Ebgiwin reserves the right and at its sole discretion to
                        refuse, limit, suspend or withdraw access to the
                        Platform or the membership of any person if Ebgiwin
                        believes that person is under the age of 18 years or
                        that any information given is not accurate or truthful,
                        or for any other reason.
                      </li>
                      <li>
                        The Platform is not available to persons whose
                        membership has been refused, limited, suspended or
                        withdrawn by Ebgiwin.
                      </li>
                      <li>
                        No person may register as a member on the Platform more
                        than once.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Registration and Membership Obligations
                    <ol className={"m-3"}>
                      <li>
                        In order to access the Services and utilise the Platform
                        You will be required to create an account and register
                        information with Us in order to create a membership
                        (“Membership”). You agree that any information provided
                        to Us in pursuant thereto will be complete and accurate,
                        that You will not register under the name of, nor
                        attempt to enter the Membership under the name of,
                        another person, and that You will not adopt a username
                        that We in Our sole discretion, deem offensive. We
                        reserve the right to not allow You to register if We
                        believe that You will not comply with these Terms.
                      </li>

                      <li>
                        You are responsible to promptly update details of Your
                        Membership such that they are at all times considered
                        true, accurate, current and complete. If You provide any
                        information that is untrue, inaccurate, not current or
                        incomplete or if Ebgiwin suspects in Our sole discretion
                        that such information is untrue, inaccurate, not current
                        or incomplete, or not in accordance with this User
                        Agreement, We have the right to suspend indefinitely,
                        limit or withdraw Your Membership and/or Your access to
                        the Platform.
                      </li>
                      <li>
                        When registering Your account on the Platform in order
                        to create Your Membership, You will be required to
                        provide certain information and register a username and
                        password for use of the Platform. To keep Your account
                        secure You have to protect Your account with reasonable
                        means, and You are responsible at all times for
                        maintaining the confidentiality of, and restricting
                        access to and use of, Your account and password to the
                        Platform. You agree to accept responsibility for all
                        activities that occur under Your account with Us. If
                        someone other than Yourself uses the Platform with Your
                        login information You will be held accountable for all
                        actions made in Your name. In the event of termination,
                        You will no longer be authorized to access the Platform
                        or Your Membership, and We reserve the right to use any
                        means possible to enforce this termination. You
                        undertake to immediately notify Ebgiwin of any
                        unauthorized use of Your Membership or any other
                        security breach.
                      </li>
                      <li>
                        You may not, at any point in time, use any other
                        person’s Membership. You agree to reimburse Ebgiwin in
                        full for any costs, expenses and damages caused by
                        improper, unauthorized or illegal use of Your Membership
                        and password by You or any person obtaining access to
                        the Platform.
                      </li>
                      <li>
                        You may not transfer or sell Your Membership with
                        Ebgiwin to another party and You undertake that You are
                        solely responsible for all activity of the Membership.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Electronic Communication
                    <ol className={"m-3"}>
                      <li>
                        In order to access the Services and utilise the Platform
                        You will be required to create an account and submit
                        personal information with Us in order to create a
                        membership (“Membership”). You agree that any
                        information provided to Us in these areas will be
                        complete and accurate, that You will not register under
                        the name of, nor attempt to enter the Membership under
                        the name of, another person, and that You will not adopt
                        a username that We in Our sole discretion, deem
                        offensive. We reserve the right to not allow You to
                        register if We believe that You will not comply with
                        these Terms.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Conduct
                    <ol className={"m-3"}>
                      <li>
                        You may not access or use the Platform or the Services
                        provided via the Platform for any purpose other than the
                        purpose for which we make it available to You. We may
                        prohibit certain activities in connection with the
                        Platform and Services in our discretion. These
                        prohibited activities include, without limitation, the
                        following:
                        <ol className={"m-3"}>
                          <li>
                            Criminal, delictual or tortious activity, including
                            fraud, trafficking in obscene material, drug
                            dealing, gambling, harassment, stalking, spamming,
                            copyright infringement, patent infringement, or
                            theft of trade secrets.
                          </li>
                          <li>
                            Advertising to, or solicitation of, any User to buy
                            or sell any products or services, except with the
                            express written approval of Ebgiwin.
                          </li>
                          <li>
                            Transmitting chain letters or junk email to other
                            Users.
                          </li>
                          <li>
                            Using any information obtained from the service in
                            order to contact, advertise to, solicit or sell any
                            products or services to any User without their prior
                            explicit consent.
                          </li>
                          <li>
                            Engaging in any automated use of the Service, such
                            as using scripts to send comments or messages.
                          </li>
                          <li>
                            Interfering with, disrupting or creating an undue
                            burden on the service or the networks or services
                            connected to the Service.
                          </li>
                          <li>
                            Attempting to impersonate another User or person.
                          </li>
                          <li>
                            Using the username or account of another User.
                          </li>
                          <li>
                            Using any information obtained from the Service in
                            order to harass, abuse or harm another person.
                          </li>
                          <li>
                            Accepting payment of anything of value from a third
                            person in exchange for Your performance of any
                            commercial activity on or through the Service on
                            behalf of that person.
                          </li>
                          <li>
                            Using the Service in a manner inconsistent with any
                            and all applicable laws and regulations.
                          </li>
                          <li>
                            Activities which are harmful, abusive, unlawful,
                            threatening, harassing, blasphemous, immoral,
                            defamatory, obscene, pornographic, paedophilic,
                            libellous, invasive of another's privacy or other
                            rights, hateful, or racially, ethnically
                            objectionable, disparaging, relating or encouraging
                            money laundering or illegal gambling or harms or
                            could harm minors in any way or otherwise unlawful
                            in any manner whatsoever.
                          </li>
                          <li>
                            Any activity which harasses, degrades, intimidates
                            or is hateful towards any individual or group of
                            individuals on the basis of religion, gender, sexual
                            orientation, race, ethnicity, age, or disability.
                          </li>
                          <li>
                            Any activity which violates any applicable equal
                            employment laws, including but not limited to those
                            prohibiting the stating, in any advertisement for
                            employment, a preference or requirement based on
                            race, color, religion, sex, national origin, age, or
                            disability of the applicant.
                          </li>
                          <li>
                            Any activity which includes personal or identifying
                            information about another person without that
                            person's explicit consent.
                          </li>
                          <li>
                            Any activity which impersonates any person or
                            entity, including, but not limited to, an Ebgiwin
                            employee, or falsely states or otherwise
                            misrepresents an affiliation with a person or entity
                          </li>
                          <li>
                            Any activity which deceives or misleads the
                            addressee about the origin of such messages or
                            communicates any information which is grossly
                            offensive or menacing in nature. Additionally, You
                            agree not to:
                          </li>
                          <li>
                            contact anyone who has asked not to be contacted, or
                            make unsolicited contact with anyone for any
                            commercial purpose;
                          </li>
                          <li>
                            use automated means, including spiders, robots,
                            crawlers, data mining tools, or the like to download
                            or scrape data from the Service, except for internet
                            search engines (e.g. Google) and non-commercial
                            public archives (e.g. archive.org) that comply with
                            our robots.txt file; and,
                          </li>
                          <li>
                            attempt to gain unauthorized access to computer
                            systems owned or controlled by Ebgiwin or engage in
                            any activity that disrupts, diminishes the quality
                            of, interferes with the performance of, or impairs
                            the functionality of, the Platform.
                          </li>
                        </ol>
                      </li>

                      <li>
                        Any content uploaded by You shall be subject to
                        applicable laws and may be disabled, or may be subject
                        to investigation under applicable laws. Furthermore, if
                        You are found to be in non-compliance with such laws and
                        regulations, we may terminate Your account/block Your
                        access to the Platform, or any part thereof, and we
                        reserve the right to remove any non-compliant content
                        uploaded by You.
                      </li>

                      <li>
                        You are solely responsible for the consequences, losses
                        or damages we may incur, directly or indirectly, or
                        suffer due to any activities conducted by You.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Payment Gateway
                    <ol className={"m-3"}>
                      <li>
                        If You make a payment for Our Services on our Platform,
                        the details You are asked to submit will be provided
                        directly to our payment provider via a secured
                        connection.
                      </li>
                      <li>
                        The cardholder must retain a copy of transaction records
                        and merchant policies and rules.
                      </li>
                      <li>
                        Multiple transactions may result in multiple postings to
                        the cardholder’s monthly statement.
                      </li>
                      <li>
                        The product prices per unit displayed on Our Platform
                        are inclusive of VAT.
                      </li>
                      <li>
                        Users may be charged in US Dollar (United States Dollar)
                        or any other currency depending on where they are using
                        the platform from and what type of Credit Card they are
                        using. In such cases, there may be slight discrepancies
                        in values due to changes in foreign exchange rates.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Refund & Cancellation Policy
                    <ol className={"m-3"}>
                      <li>
                        All sales are final with no cancellation options.{" "}
                      </li>
                      <li>
                        For any defective products, Ebgiwin will offer You
                        replacement of the defective product only, subject to
                        any warranties and/or guarantees provided by the
                        manufacturer of the product. Ebgiwin shall have no
                        liability or responsibility in relation to any defect
                        regarding a prize awarded as a result of a Campaign
                        Draw.
                      </li>
                      <li>No other form of refund is permissible. </li>
                    </ol>
                  </li>

                  <li>
                    Intellectual Property Rights
                    <ol className={"m-3"}>
                      <li>
                        The Platform and the materials on the Platform,
                        including without limitation, the text, software,
                        scripts, graphics, photos, sounds, music, videos,
                        interactive features and the like ("Materials") and the
                        trademarks, service marks and logos contained therein
                        ("Marks"), are owned by or licensed to Ebgiwin, and are
                        subject to copyright and other intellectual property
                        rights under UAE and foreign laws and international
                        conventions. In connection with the Services, the
                        Platform may display certain intellectual property goods
                        belonging to third parties. Use of these goods may be
                        subject to license granted by third parties to Ebgiwin.
                        You shall, in no event, reverse engineer, decompile, or
                        disassemble such goods and nothing herein shall be
                        construed to grant You any right in relation to such
                        goods. Materials on the Platform are provided to You AS
                        IS for Your information and personal use only and may
                        not be used, copied, reproduced, distributed,
                        transmitted, broadcast, displayed, sold, licensed, or
                        otherwise exploited for any other purposes whatsoever
                        without the prior written consent of the respective
                        owners. Ebgiwin reserves all rights not expressly
                        granted herein to the Platform and the Materials. You
                        agree to not engage in the use, copying, or distribution
                        of any of the Materials other than as expressly
                        permitted herein, including any use, copying, or
                        distribution of Materials of third parties obtained
                        through the Platform for any commercial purposes. You
                        agree not to circumvent, disable or otherwise interfere
                        with security related features of the Platform or
                        features that prevent or restrict use or copying of any
                        Materials or enforce limitations on use of the Platform
                        or the Materials therein. Content displayed on or
                        through the provision of the services is protected by
                        copyright as a collective work and/or compilation,
                        pursuant to copyrights laws, other laws, and
                        international conventions. Any reproduction,
                        modification, creation of derivative works from or
                        redistribution of the Platform, the Materials, or the
                        collective work or compilation is expressly prohibited.
                        Copying or reproducing the Platform, the Materials, or
                        any portion thereof for further reproduction or
                        redistribution is expressly prohibited.
                      </li>
                    </ol>
                  </li>

                  <li>
                    User Submissions
                    <ol className={"m-3"}>
                      <li>
                        You accept that various things can go wrong when using
                        the Platform and the Services provided through the
                        Platform. You take on the responsibility and liability
                        of these events happening and will have no claim against
                        Ebgiwin if they happen. When using the Platform, You
                        will be exposed to Materials from a variety of sources,
                        and that, as far as applicable law allows, Ebgiwin is
                        not responsible for the accuracy, usefulness, safety, or
                        intellectual property rights of or relating to such
                        Materials, and You agree and assume all liability for
                        Your use. You further may be exposed to Materials that
                        are inaccurate, offensive, indecent, objectionable,
                        defamatory or libelous and, as far as the law allows,
                        You agree to waive, and hereby do waive, any legal or
                        equitable rights or remedies You have or may have
                        against Ebgiwin with respect thereto.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Digital Media
                    <ol className={"m-3"}>
                      <li>
                        You consent to the use of Your name in the event You are
                        the Winner of any of the Campaign Draws. This includes
                        but is not limited to use of Your name in the local and
                        regional press, on the Platform and on any social
                        (online) media profile associated with Us.
                      </li>
                      <li>
                        You consent that We have the right to use any and all
                        digital records made by Us including, but not limited
                        to, photographs, videos and call recordings of, the
                        Prize, the Campaign Draw, the winning phone call and the
                        collection of the Prize, on the Platform and on any
                        social (online) media profile associated with Us. These
                        digital records may include imagery and/or voice
                        recordings of the Winner and/or their name.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Disclaimer for Third Party Applications
                    <ol className={"m-3"}>
                      <li>
                        The use of any third party application is subject to any
                        terms and conditions provided with such third party
                        application and is not governed by Us. The use of third
                        party applications is solely Your responsibility since
                        Ebgiwin does not govern the use of such third party
                        applications. Ebgiwin is not responsible for any third
                        party applications and You acknowledge that such
                        applications may be modified or removed by their
                        original publisher and/or respective rights owner at any
                        time. You assume all responsibility and risk of use of
                        any third party applications (including any content
                        therein) and Ebgiwin hereby disclaims any and all
                        liability to You or any third party related thereto.
                      </li>
                      <li>
                        Ebgiwin does not have any obligation to examine or scan
                        third party applications, for any purpose, and is not
                        responsible for the accuracy, completeness,
                        appropriateness or legality of any third party
                        applications. The fact that a third party application is
                        available via any online platform or otherwise is not an
                        endorsement, authorization or representation of
                        Ebgiwin’s affiliation with any third party, nor is it an
                        endorsement of such third party application and You
                        hereby waive, any legal or equitable rights or remedies
                        You have or may have against Ebgiwin with respect
                        thereto. Ebgiwin exercises no control over third party
                        applications, products, services, software or any online
                        platform and We are not responsible for their
                        performance, do not endorse them, and are not
                        responsible or liable for any content, advertising, or
                        other materials available through any third party
                        applications, products, services, software and any
                        online platform. We are not responsible or liable,
                        directly or indirectly, for any damage or loss caused to
                        You by Your use of or reliance on any goods or services
                        available through the third party applications,
                        products, services, software and any online platform.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Limitations of Liability
                    <ol className={"m-3"}>
                      <li>
                        To offer a reliable and enjoyable service We need to fix
                        bugs, install updates and perform general diagnosis and
                        maintenance. We, cannot warrant that the Platform and
                        Services will always be uninterrupted, problem-free,
                        free of omission, or error-free. To the extent allowed
                        by law, content and function of the Platform, and
                        Services, are provided on an "as is" basis without
                        warranties of any kind, either expressed or implied.
                      </li>
                      <li>
                        Information obtained on the Platform may not always be
                        accurate. We use other providers to provide data on
                        which We base the information detailed on the Platform.
                        We try to make sure that the data is correct and up
                        to-date together with Our partners, but We cannot
                        guarantee that it will always be.
                      </li>
                      <li>
                        In no event will Ebgiwin nor will any of Our data
                        providers be liable for any incidental, indirect,
                        special, punitive, exemplary, or consequential damages,
                        arising out of Your use of or inability to use the App,
                        including without limitation, loss of revenue or
                        anticipated profits, loss of goodwill, loss of business,
                        loss of data, computer failure or malfunction, or any
                        other damages.
                      </li>
                      <li>
                        WE EXPRESSLY DISCLAIM ALL WARRANTIES AND CONDITIONS OF
                        ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                        LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                        NON-INFRINGEMENT.
                      </li>
                      <li>
                        In conjunction with the limitation of warranties as
                        explained herein, You expressly understand and agree
                        that any claim against Us shall be limited to the amount
                        You paid to Us, if any, for use of our Platform and/or
                        Services.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Indemnity
                    <ol className={"m-3"}>
                      <li>
                        You agree to defend, indemnify and hold harmless Ebgiwin
                        and its affiliates and subsidiaries, and its and their,
                        officers, directors, shareholders, successors, assigns,
                        agents, service providers, suppliers and employees, from
                        and against any and all claims, damages, obligations,
                        losses (whether direct, indirect or consequential),
                        liabilities, costs or debt, and expenses (including but
                        not limited to attorneys' fees) arising from: (i) Your
                        breach of any provision of this User Agreement; (ii)
                        Your breach of any third party right, including without
                        limitation any copyright, trademark, trade secret or
                        other property, or privacy right; (iii) Your use of the
                        Platform or the Service, or (iv) any claim that the
                        Platform, Services or any Materials caused damage to You
                        or a third party. This defence and indemnification
                        obligation will survive termination, modification or
                        expiration of this User Agreement and Your use of the
                        Service and the Platform.
                      </li>
                    </ol>
                  </li>

                  <li>
                    No Spam Policy
                    <ol className={"m-3"}>
                      <li>
                        You understand and agree that sending unsolicited email
                        advertisements or other unsolicited communications to
                        Ebgiwin email addresses or through Ebgiwin information
                        systems are expressly prohibited by these Terms. Any
                        unauthorized use of Ebgiwin computer systems is a
                        violation of this User Agreement and certain applicable
                        laws, in particular the UAE Cybercrimes legislation.
                        Such violations may subject the sender and his or her
                        agents to civil and criminal penalties. Please note that
                        the UAE Cybercrimes legislation carries significant
                        penalties including imprisonment. In case You intend to
                        solicit, or contact Our Users by obtaining their email
                        or phone numbers from Our Platform, we may report this
                        behavior to the relevant authorities, who then may
                        decide to prosecute You under the relevant UAE laws.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Limitation and Termination of Service
                    <ol className={"m-3"}>
                      <li>
                        Ebgiwin may establish limits from time to time
                        concerning use of the Service, including among others,
                        the maximum number of days that content will be
                        maintained or retained by the Service, the maximum
                        number and size of postings, e-mail messages, or other
                        Content that may be transmitted or stored by the
                        Service, and the frequency with which You may access the
                        Service or the Platform.
                      </li>
                      <li>
                        Ebgiwin reserves the right at any time to modify or
                        discontinue the Service on the Platform (or any part
                        thereof) with or without notice, and that Ebgiwin shall
                        not be liable to You or to any third party for any such
                        modification, suspension or discontinuance of the
                        Service.
                      </li>
                      <li>
                        You acknowledge and agree that Ebgiwin, in its sole and
                        absolute discretion, has the right (but not the
                        obligation) to delete or deactivate Your account, block
                        Your e-mail or IP address, or otherwise terminate Your
                        access to or use of the Service or Platform (or any part
                        thereof), immediately and without notice for any reason
                        or no reason at all. Further, You agree that Ebgiwin
                        shall not be liable to You or any third-party for any
                        termination of Your access to the Platform or the
                        Service.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Assignment
                    <ol className={"m-3"}>
                      <li>
                        These Terms, and any rights and licenses granted
                        hereunder, may not be transferred or assigned by You,
                        but may be assigned by Ebgiwin without restriction. Any
                        assignment or transfer by You shall be null and void.
                      </li>
                    </ol>
                  </li>
                  <li>
                    General Information
                    <ol className={"m-3"}>
                      <li>
                        The failure of Ebgiwin to exercise or enforce any right
                        or provision of this User Agreement shall not constitute
                        a waiver of such right or provision. 
                      </li>
                      <li>
                        If any provision of this User Agreement is found by a
                        court of competent jurisdiction to be invalid
                        (including, without limitation, because such provision
                        is inconsistent with the laws of another jurisdiction)
                        or inapplicable, We and You nevertheless agree that the
                        court should endeavour to give effect to Our intentions
                        as reflected in this User Agreement. If any provision or
                        provisions of this User Agreement is held to be invalid,
                        illegal or unenforceable, the validity, legality and
                        enforceability of the remaining provisions of this User
                        Agreement shall not in any way be affected or be
                        impaired.
                      </li>
                      <li>
                        YOU AGREE THAT ANY CAUSE OF ACTION BROUGHT BY YOU AND
                        ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICE
                        AND/OR THE PLATFORM MUST COMMENCE WITHIN A REASONABLE
                        TIME AND IN ANY EVENT WITHIN ONE (1) YEAR AFTER THE
                        CAUSE OF ACTION ACCRUES.
                      </li>
                    </ol>
                  </li>

                  <li> 
                    Governing law and Dispute Resolution
                    <ol className={"m-3"}>
                      <li>
                        Any dispute arising out of or in connection with this
                        User Agreement, including any question regarding its
                        existence, validity or termination, shall be subject to
                        the exclusive jurisdiction of the Courts of the Dubai
                        International Financial Centre. This Agreement shall be
                        governed by and construed in accordance with the laws of
                        the Dubai International Financial Centre.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default UserAgreement;
