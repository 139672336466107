 
import CountryCodes from "./country-codes";
import React, { useState,useContext } from "react";
import { Col, Form, Button ,Spinner,  Row} from "react-bootstrap";
import Colors from "../../../config/color";
import { firebaseAuth } from "../../../provider/authprovider";
import generateHash from "../../../helpers/hash";
import LoginActions from "../actions/form-actions";
import sendEmail from "../../../helpers/email/email";
import registrationTemplate from "../../../templates/registration";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
 
const RegistrationForm = (props) => {
  const [validated, setValidated] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registrationData, setRegistrationData] = useState({ email: "", password: "" ,firstName:"",lastName:"",confirmPassword:"",countryCode:"",mobileNo:"" });
  const {
    handleSignin,
    handleSignup,
    setUser,
    errors,
    setErrors,
    token,
  } = useContext(firebaseAuth); 
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleRegistration(event)
    }
    setValidated(true);
  };
  const handleChange = (e) => {
    setRegistrationData({
      ...registrationData,
      [e.target.name]: e.target.value,
    }); 
  };
  const handleRegistration = (e) => {
    e.preventDefault(e);
    if (registrationData.password === registrationData.confirmPassword) { 
        setLoading(true);
        setUser({ email: registrationData.email, password: registrationData.password });
        handleSignup(registrationData, function (_uid, user, status) {
          console.log("loading chagne  out")
          if (status === "success") {
            console.log("loading chagne  in")
            setLoading(false);
            user["uid"] = _uid;
            Firestore.saveWithId("users", _uid, user);
            delete registrationData.confirmPassword;
            delete registrationData.password;
            localStorage.setItem("user", JSON.stringify(registrationData)); 
            // sendEmail(
            //   registrationData.email,
            //   "New account successfully registered",
            //   registrationTemplate
            // );
            LoginActions.showAlert(
              "Email has been sent to you account",
              setAlertMsg,
              setShowAlert
            );
          } else {
            setLoading(false);
            LoginActions.showAlert("Email already Exist", setAlertMsg, setShowAlert);
          }
        }); 
    } else {
      LoginActions.showAlert(
        "Password and Confirm Passoword does not match",
        setAlertMsg,
        setShowAlert
      );
    } 
    console.info("temp object", JSON.stringify(registrationData));
  };
 
  return ( 
    <div className="auth-body ">
    <Row className="auth-body d-flex justify-content-center">
      {showAlert ? (
        <div
          style={{ backgroundColor: "red", color: "white", width: "100%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="light" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) :  ""}
       <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} md="6" controlId={generateHash()}>
          <Form.Control
            type="text"
            name="firstName"
            onChange={handleChange}
            className="auth-input"
            placeholder="First Name"
            required
          />
          <Form.Control.Feedback type="invalid" tooltip>
            First Name required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId={generateHash()}>
          <Form.Control
            required
            type="text"
            name="lastName"
            onChange={handleChange}
            className="auth-input"
            placeholder="Last Name"
          />
          <Form.Control.Feedback type="invalid" tooltip>
          Last Name required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="12" controlId={generateHash()}>
          <Form.Control
            type="email"
            name="email"
            onChange={handleChange}
            className="auth-input"
            placeholder="email"
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid" tooltip>
            Email required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="12" controlId={generateHash()}>
          <Form.Control
            type="password"
            name="password"
            onChange={handleChange}
            className="auth-input"
            autoComplete="off"
            placeholder="Password"
            minLength={8}
            required
          />
          <Form.Control.Feedback type="invalid" tooltip>
            Password required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="12" controlId={generateHash()}>
          <Form.Control
            type="password"
            name="confirmPassword"
            onChange={handleChange}
            className="auth-input"
            placeholder="Confirm Password"
            minLength={8} 
            required
          />
          <Form.Control.Feedback type="invalid" tooltip>
            Confirm Password required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId={generateHash()}>
          <CountryCodes onChange={handleChange} />
          <Form.Control.Feedback type="invalid" tooltip>
            Country Code required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="8" controlId={generateHash()}>
          <Form.Control
            type="number"
            name="mobileNo"
            className="auth-input"
            onChange={handleChange}
            placeholder="Mobile Phone"
            style={{ marginTop: "unset" }} 
            required
          />
          <Form.Control.Feedback type="invalid" tooltip>
            Mobile Phone required ( Max 10 digits)
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Col md={12}>
          <div className="auth-footer justify-content-end">
            <Button
              className="auth-login-btn"
              style={{ color: Colors.primary }}
              type={"submit"}
            >
              Register Now
            </Button>
          </div>
        </Col>
      </Form.Row>
    </Form>
       
    </Row>
  </div>


   
  );
};

export default RegistrationForm;
