import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Colors from "../config/color";
import "./stylesheet/footer.css";
//
const Footer = () => {
  //   const { footerState } = useContext(Context)
  return (
    <Row className="footer-container">
      <Container className=" ">
        <Row className="d-flex justify-content-between ">
          <Col lg={4}>
            <Row className="site-links">
              <Col className="d-flex flex-column" lg={6}>
                <h2>Quick Links </h2>

                <p>
                  <Link style={{ color: Colors.onPrimary }} to="/faq">
                    FAQ
                  </Link>
                </p>
                <p>
                  <Link style={{ color: Colors.onPrimary }} to="/about">
                    About Deals
                  </Link>
                </p>
                <p>
                  <Link style={{ color: Colors.onPrimary }} to="/charity">
                    Charity
                  </Link>
                </p>
              </Col>
              <Col className="d-flex flex-column social-link" lg={6}>
                <h2>Customer Service </h2>

                <p>
                  <Link style={{ color: Colors.onPrimary }} to="/agreement">
                    UserAgreement
                  </Link>
                </p>
                <p>
                  <Link style={{ color: Colors.onPrimary }} to="/terms">
                    Terms
                  </Link>
                </p>
                <p>
                  <Link style={{ color: Colors.onPrimary }} to="/policy">
                    Privacy Policy
                  </Link>
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="social-links" lg={4}>
            <Row className="d-flex flex-column footer-social-box ">
              <Col lg={12}>
                <i class="fa fa-facebook-square    "></i>
                <i class="fa fa-twitter-square    "></i>
                <i class="fa fa-instagram" aria-hidden="true"></i>
                <i class="fa fa-linkedin-square    "></i>
                <i class="fa fa-youtube-square    "></i>
              </Col>
              <Col lg={12}>
                <p>For the ultimate shopping experience download our app.</p>
              </Col>
              <Col className="d-flex flex-row" lg={12}>
                <img
                  src="./assets/img/playstore.png"
                  alt="Appnova Screen"
                  className="img-responsive  "
                  data-wow-delay="0.2s"
                  data-wow-duration="1s"
                />
                <img
                  src="./assets/img/applestore.png"
                  alt="Appnova Screen"
                  className="img-responsive apple  "
                  data-wow-delay="0.2s"
                  data-wow-duration="1s"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Col
        md={12}
        sm={12}
        lg={12}
        className="d-flex justify-content-between   copyright-footer"
      >
        <Container
          style={{ alignItems: "center" }}
          className="d-flex   justify-content-between "
        >
          <Col className="d-flex" lg={6}>
            <img className="footer-logo" src="/assets/img/logo/logo.png" />
            <ul className="d-flex ">
              <li>© 2020 All rights reserved</li>
              <li>. User Agreement</li>
              <li>. Privacy Policy</li>
            </ul>
          </Col>
          <Col className="d-flex" lg={3}>
            <p>We accept</p>
            <img
              style={{ width: "75%" }}
              src="./assets/img/footercards.PNG"
              alt="Appnova Screen"
              className="img-responsive apple  "
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            />
          </Col>
        </Container>
      </Col>
    </Row>
  );
};
export default Footer;

{
  /* <img src="https://www.ebigwin.com/assets/upload/pics/1601285252-logo2-4.png" /> */
}

{
  /* <i class="fa fa-facebook-square    "></i>    
              <i class="fa fa-instagram-square    "></i>   
              <i class="fa fa-twitter-square    "></i>   
              <i class="fa fa-linkedin-square    "></i>   
              <i class="fa fa-youtube-square    "></i>     */
}
