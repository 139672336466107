import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

const Charity = (props) => {
  return (
    <Container>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <h3 className={"m-4"}>Charities</h3>
          <p>
            Our mission objective at Ebigwin is to transform lives, not just
            those of our customers but of children who are less fortunate around
            the world. To achieve that aim, we created ‘Give 2 Live’ as part of
            our ecommerce experience. ‘Give 2 Live’ allows you to donate your
            product, or a percentage of its sale, to our affiliate charity
            partners. For your generosity, Ebigwin promises to reward every
            donation by doubling the number of complimentary entries you receive
            for the prize draw. In this way, we hope to create a genuine win-win
            for all involved. Since its inception, Dubai Cares, part of Mohammed
            bin Rashid Al Maktoum Global Initiatives, has been working towards
            providing children and young people in developing countries with
            access to quality education through the design and funding of
            programs that aim to be integrated, impactful, sustainable and
            scalable. Dubai Cares is playing a key role in helping achieve the
            United Nations Sustainable Development Goal (SDG) 4, which aims to
            ensure inclusive and quality education for all and promote lifelong
            learning by 2030, by supporting programs in early childhood
            development, access to quality primary and secondary education,
            technical and vocational education and training for youth as well as
            a particular focus on education in emergencies and protracted
            crises. ANB* by supporting Dubai Cares through 'Give 2 Live', you
            are donating a percentage of the sale price.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Charity;
