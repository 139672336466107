import React, { useContext, useState, useEffect } from "react";
import Context from "../context/Context";
// import Nav from 'react-bootstrap/Nav'
// import Dropdown from 'react-bootstrap/Dropdown'
// import Button from 'react-bootstrap/Button'
// import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Link, Redirect } from "react-router-dom";
import { firebaseAuth } from "../provider/authprovider";
import { ReactComponent as Logo } from "../assets/svgs/brand.svg";
import { Container, Dropdown } from "react-bootstrap";
import generateHash from "../helpers/hash";
import { ReactComponent as Cart } from "../components/pages/index/assets/cart.svg";
import { cartData } from "../helpers/data/cart";
import Flex, { FlexProps } from "../components/flex-box/flex";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import "./stylesheet/nav.css";
import Login from "../components/auth/auth";

const Navbar = () => {
  const {
    count,
    setCount,
    currency,
    setCurrency,
    hideCurrencyBar,
  } = useContext(Context);
  const { handleSignout, token } = useContext(firebaseAuth);
  const [lgShow, setLgShow] = useState(false);
  const [afterLogout, setAfterLognout] = useState(false);

  const handlelogout = () => {
    handleSignout();
    setAfterLognout(true);
  };
  const handleAuthCheck = () => {
    if (token === null) {
      return <Redirect to="/"></Redirect>;
    }
  };
  const [cart, setCart] = useState(0);

  useEffect(function () {
    if (
      localStorage.getItem("cart") === undefined ||
      localStorage.getItem("cart") === null
    ) {
    } else {
      setCart(JSON.parse(localStorage.getItem("cart")));
      setCount(Object.keys(JSON.parse(localStorage.getItem("cart"))).length);
    }
    // Actions.data("campaigns", function (campaigns) {
    //   setCampaigns(campaigns);
    //   console.log("campaigns   ::;; "+ JSON.stringify(campaigns));
    // });
  }, []);
  const handleCurrency = (e) => {
    setCurrency(e.target.value);
    console.log("currency ::: ", currency);
  };
  return (
    <>
      {afterLogout ? <Redirect to="/"></Redirect> : ""}
      {/*  Header Section Begin */}
      <header className="header" id={"mobile-header"}>
        {hideCurrencyBar ? (
          ""
        ) : (
          <Row style={{ background: "#ebebeb" }}>
            <Container>
              <Col className="d-flex" lg={4}>
                <div>
                  <p style={{ paddingTop: "13px" }}>Currency :</p>
                </div>
                <Form.Group
                  style={{ paddingTop: "8px" }}
                  controlId="exampleForm.ControlSelect1"
                >
                  <Form.Control
                    style={{
                      border: "unset",
                      background: "#ebebeb",
                      paddingTop: "8px",
                    }}
                    size="sm"
                    as="select"
                    onChange={handleCurrency}
                  >
                    <option value="USD">USD - US Dollar </option>{" "}
                    <option value="AED">AED - United Arab Emirates Dirham </option>
                    <option value="BHD">BHD - Bahraini Dinar   </option>
                    <option value="INR">INR - Indian Rupee</option>{" "}
                    <option value="KWD">KWD - Kuwaiti Dinar</option>
                    <option value="OMR">OMR - Omani Rial</option>
                    <option value="SAR">SAR - Saudi Riyal </option>{" "}
                    
                  </Form.Control>
                </Form.Group>
              </Col>
            </Container>
          </Row>
        )}
        <div className="container">
          <div
            className="row d-flex align-items-center"
            id={"navbar-mobile-container"}
          >
            <div id={"app-brand"} className="col-lg-2 col-md-2">
              <div className="header__logo">
                {/* <a href="./index.html"><img src="./assets/img/logo.png" alt=""/></a> */}
                <Link to="/">
                  <img src="/assets/img/logo/logo.png" />
                </Link>
              </div>
            </div>
            <div className="col-lg-7 col-md-7" id={"app-nav"}>
              <nav className="header__menu">
                <ul>
                  <li>
                    <Link to="/">Deals</Link>
                  </li>

                  {token === null ? (
                 <li className="  ">
                    <>
                      <li className=" ">
                        <Link to="/cart">Cart</Link>
                      </li>
                      <li className="  ">
                        <Link to="/login">Checkout</Link>
                      </li>
                    </>
               </li>
                  ) : (
                    <>
                      <li className=" ">
                        <Link to="/cart">Cart</Link>
                      </li>
                      <li className="  ">
                        <Link to="/checkout">Checkout</Link>
                      </li>
                    </>
                  )}
                  {/* <li className=" ">
                    <Link to="/winner">Winners</Link>
                  </li> */}
                </ul>
              </nav>
            </div>
            {/* <div
              className="col-lg-3 col-md-3 d-flex pr-5"
              style={{ justifyContent: "flex-end" }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  className={"btn btn-sm bg-transparent border-0"}
                  style={{ color: "#707070" }}
                  id="dropdown-basic"
                >
                  Profile
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ backgroundColor: "#73a47" }}>
                  <Dropdown.Item>
                    <Link to="/setting">Setting</Link>
                  </Dropdown.Item>

                  {(localStorage.getItem("token") === null) === undefined ? (
                    <Dropdown.Item href="#">Login or Register</Dropdown.Item>
                  ) : (
                    <Dropdown.Item onClick={handlelogout}>Logout</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <Flex
              direction={FlexProps.direction.row}
              className="col-lg-3 col-md-3 pr-5 justify-content-space-evenly"
              id={"app-actions"}
            >
              {token === null ? (
                <Link to="/login">
                  {" "}
                  <span style={{ color: "#aeaeae" }}>
                    Login or Register
                  </span>{" "}
                </Link>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    className={"btn btn-sm bg-transparent border-0"}
                    style={{ color: "#707070" }}
                    id="dropdown-basic"
                  >
                    Profile
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className={"border-0"}
                    style={{ backgroundColor: "#73a47" }}
                  >
                    <>
                      <span className={"dropdown-item"}>
                        <Link
                          style={{ color: "#212529 !important" }}
                          to="/setting"
                        >
                          Settings
                        </Link>
                      </span>
                 
                      <span className={"dropdown-item"}>
                        <a onClick={handlelogout}>Logout</a>
                        </span>
                    </>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <span style={{ position: "relative" }}> 
              <Link to="/cart">
                <Cart /> <strong style={{ position: "absolute" ,marginLeft:"5px" }}>{count}</strong>
              </Link>
              </span>
              {/* <Dropdown>
                <Dropdown.Toggle
                  className={"btn btn-sm bg-transparent border-0"}
                  style={{ color: "#707070" }}
                  id="dropdown-basic"
                >
                  <span className={"p-2"}>
                    {localStorage.getItem("cart") === undefined ||
                    localStorage.getItem("cart") === null ? (
                      <span></span>
                    ) : (
                      <span>{count}</span>
                    )}
                  </span>

                </Dropdown.Toggle>
                <Dropdown.Menu style={{ backgroundColor: "#73a47" }}>
                  {cartData().map((item) => (
                    <Dropdown.Item
                      key={generateHash()}
                      className={"d-flex"}
                      style={{ justifyContent: "space-between" }}
                    >
                      <span>
                        {item.title} {item.count}
                      </span>
                      <span>
                        <button
                          className={"btn btn-sm border-0 bg-transparent"}
                          onClick={function () {
                            const cart = JSON.parse(
                              localStorage.getItem("cart")
                            );
                            delete cart[item.id];
                            localStorage.setItem("cart", JSON.stringify(cart));
                            setCount(
                              Object.keys(
                                JSON.parse(localStorage.getItem("cart"))
                              ).length
                            );
                          }}
                          style={{ backgroundColor: "transparent" }}
                        >
                          <i className={"fa fa-close "}></i>{" "}
                        </button>
                      </span>
                    </Dropdown.Item>
                  ))}

                  {localStorage.getItem("cart") === undefined ||
                  localStorage.getItem("cart") === null ||
                  localStorage.getItem("cart") === "{}" ? (
                    <></>
                  ) : token === null ? (
                     <></>
                  ) : (
                    <Link
                      className={"d-flex justify-content-center"}
                      to={"/checkout"}
                    >
                      <span href="#" className={"btn btn-sm btn-success"}>
                        checkout
                      </span>
                    </Link>
                  )}
                </Dropdown.Menu>
              </Dropdown> */}
            </Flex>
          </div>
        </div>
      </header>
      <div
        className="modal modal-search fade"
        role="dialog"
        aria-labelledby="searchModal"
        aria-hidden="true"
      >
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          variant="secondary"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              style={{ color: "black" }}
              type="text"
              className="form-control "
              placeholder="SEARCH"
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Navbar;
