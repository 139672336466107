var qs = require("querystring");
var axios = require("axios");
const sendEmail = (email, subject, template) => {

    var data = qs.stringify({
        email: email,
        subject: subject,
        template: template,
    });
    var config = {
        method: "post",
        url: "https://arcane-thicket-76904.herokuapp.com/email",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
    };

    axios(config)
        .then(function(response) {
            console.log(JSON.stringify(response.data));
        })
        .catch(function(error) {
            console.log(error);
        });
};

module.exports = sendEmail;