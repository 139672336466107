 import { parse } from "query-string";

 var tempColors = null;
 const tempObject = () => {
     tempColors = JSON.parse(localStorage.getItem("colors"));
 };
 tempObject();
 var Colors =
     (tempColors === null) ? {
         onPrimary: "#A4A5AD",
         primary: "#303346",
         secondary: "#303346a6"
     } : {
         primary: tempColors[0]["primary"],
         onPrimary: tempColors[0]["onPrimary"],
         secondary: tempColors[0]["secondary"],
     };

 //  var Colors = {
 //      onPrimary: "#A4A5AD",
 //      primary: "#303346",
 //      secondary: "#303346a6"
 //  };
 export default Colors;