import React, { useState, useContext } from "react";
import { Col, Form, Button, Spinner, Row } from "react-bootstrap";
import Colors from "../../../config/color";
import { firebaseAuth } from "../../../provider/authprovider";
import generateHash from "../../../helpers/hash";
import LoginActions from "../actions/form-actions";
var temp = {};
const LoginForm = (props) => {
  const [validated, setValidated] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const {
    handleSignin,
    handleSignup,
    setUser,
    errors,
    setErrors,
    token,
  } = useContext(firebaseAuth);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleLogin(event);
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    }); 
  };
  const handleLogin = (e) => {
    e.preventDefault(e);
    setUser(loginData);
    setLoading(true);
    handleSignin(loginData, function (_uid, loginData, status) {
      if (status === "success") {
        setLoading(false);
        loginData["uid"] = _uid;
        console.log("testing status &&& ", status);
        LoginActions.document("users", _uid, function (user) {
          // delete user.data.password;
          localStorage.setItem("user", JSON.stringify(user.data));
        });
      } else {
        console.log("network error ");
        setLoading(false);
      }
    });
  };

  return (
    <div className="auth-body ">
      {showAlert ? (
        <div
          style={{ backgroundColor: "red", color: "white", width: "100%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}

      {loading ? (
        <div className="d-flex justify-content-center align-item-center">
          <Spinner animation="border" variant="light" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        ""
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md="12" controlId={generateHash()}>
            <Form.Control
              required
              type="email"
              name="email"
              onChange={handleChange}
              className="auth-input"
              placeholder="Email"
            />
            <Form.Control.Feedback type="invalid" tooltip>
              Email required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="12" controlId={generateHash()}>
            <Form.Control
              required
              type="password"
              name="password"
              onChange={handleChange}
              className="auth-input "
              placeholder="Password"
            />
            <Form.Control.Feedback type="invalid" tooltip>
              Password required
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          {/* <Col className={"d-flex align-items-center"}>
            <Form.Check
              custom
              inline
              label="ForgetPassword"
              name="statusFilter"
              onClick={props.onRest}
              type={"radio"}
              value="generated"
              className="auth-radio text-center"
              id={"generated"}
            />
          </Col> */}

          <Col className={"d-flex justify-content-end"}>
            <Button
              className="auth-login-btn "
              style={{ color: Colors.primary }}
              type={"submit"}
            >
              Login
            </Button>
          </Col>
        </Form.Row>
      </Form>
      

      <div>
        {errors.length > 0
          ? errors.map((error) => (
              <p
                key={Math.random()}
                style={{
                  color: "aqua",
                  padding: "20px",
                  backgroundColor: "red",
                  borderRadius: "10px",
                }}
              >
                {error}
              </p>
            ))
          : null}
      </div>
    </div>
  );
};

export default LoginForm;
