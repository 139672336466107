import React from "react";
import Card from "react-credit-cards";
import Actions from "../../actions/form-actions";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "./utils/utils";

import "../stylesheet/credit-cart.css";

import SupportedCards from "./card";
import Flex, { FlexProps } from "../../../flex-box/flex";
import { Firestore } from "../../../../dao/firebase/firebase-firestore";
import { cartData } from "../../../../helpers/data/cart";
import generateHash from "../../../../helpers/hash";
import { Jumbotron } from "react-bootstrap";

export default class SingleCard extends React.Component {
  constructor(props) {
    super(props);
    
  }
  
  state = {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
    formData: null,
    showForm: false,
 
    cardData: [
      {
        expiry: "12/31",
        number: "5123 1231 2312 3222",
        cvc: "1233",
        name: "DANISH ALI",
      },
      {
        name: "danish",
        expiry: "12/22",
        cvc: "2222",
        number: "4923 4234 2343 2423",
      },
    ],
  };

  _setData(data) {
    this.setState({ cardData: data });
  }
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    const _that = this;
    // Firestore.document("cards", user.uid, function (data) {
    //   const cards = [];
    //   for (const card in data["data"]) {
    //     cards.push(data["data"][card]);
    //   }
    //   _that._setData(cards)
    // });
  }
  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };
  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }
    this.setState({ [target.name]: target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { issuer } = this.state;
    var formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    // this.setState({ formData });
    // // const user= localStorage.getItem('user')
    // // console.log("user id is here ", JSON.stringify(user)  )
    delete formData["issuer"];

    let tempData = {};
    const user = JSON.parse(localStorage.getItem("user"));

    cartData().map((item, index) => {
      tempData[item.id] = {
        campaign: `campaigns/${item.id}`,
        coupons: item.product.coupons,
        donated: false,
        purchaseCount: item.count,
      };
      Firestore.saveWithId("coupons", item.id, {
        [user.uid]: {
          campaignId: item.id,
          coupons: item.product.coupons,
          contactNumber: user.mobileNo,
          firstName: user.firstName,
          lastName: user.lastName,
          userId: user.uid,
        },
      });
    });

    this.setState({
      cardData: [...this.state.cardData, formData], 
    });
    Actions.saveWithId("cards", user.uid, { [generateHash()]: formData }); 
   Firestore.saveWithId("userCampaigns", user.uid, tempData);
      formData = {};
     localStorage.removeItem("cart");
    this.form.reset();
    this.setState({showForm:false})
    this.props.shoppingDone()
  };
  handlePayWithThis=(card)=> {   
     let tempData = {};
    const user = JSON.parse(localStorage.getItem("user")); 
    console.log("this is card item form HanldePayWithThis "+ JSON.stringify(card))
   cartData().map((item, index) => {
      tempData[item.id] = {
        campaign: `campaigns/${item.id}`,
        coupons: item.product.coupons,
        donated: false,
        purchaseCount: item.count,
     };
      Firestore.saveWithId("coupons", item.id, {
        [user.uid]: {
          campaignId: item.id,
          coupons: item.product.coupons,
          contactNumber: user.mobileNo,
          firstName: user.firstName,
          lastName: user.lastName,
          userId: user.uid,
        },
      });
    });   
    // Actions.saveWithId("cards", user.uid, { [generateHash()]: card }); // this will need if Payment APi provided by client
      Firestore.saveWithId("userCampaigns", user.uid, tempData); 
   localStorage.removeItem("cart");  
  this.props.shoppingDone()
  }
  render() {
    const {
      name,
      number,
      expiry,
      cvc,
      focused,
      issuer,
      formData,
      showForm,
    } = this.state;

    return (
      <>
      
        <div key="Payment">
          <div className="App-payment">
            <div className={"m-4   "}>
              {true ? (
                <div className="mb-3">
                  <div className="App-cards">
                    <div
                      className="row App-cards-list"
                      style={{
                        display: "flex",
                        flexDirection: "flex-column !important",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.cardData.map((item) => (
                        <div
                        key={generateHash()}
                          className="mt-1 mb-1"
                          style={{
                            position: "relative",
                          }}
                        >
                          <Card
                            className="mt-1 mb-1"
                            name={item.name}
                            number={item.number}
                            expiry={item.expiry}
                            cvc={item.cvc}
                          />
                          <div
                            style={{
                              margin: "10px",
                              position: "absolute",
                              bottom: "6px",
                              zIndex: "99",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <button
                              onClick={()=>{this.handlePayWithThis(item)}}
                              className="btn btn-sm btn-success mb-3  " 
                            >
                              PAY WITH THIS
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            {showForm ?   <Card
                number={number}
                name={name}
                expiry={expiry}
                cvc={cvc}
                focused={focused}
                callback={this.handleCallback}
              />:""}
            </div>
            {showForm ? (
              ""
            ) : (
              <Flex justifyContent={FlexProps.justifyContent.flexEnd}>
                <button
                  className={"btn btn-sm btn-success mb-3"}
                  onClick={() => {
                    this.setState({ showForm: true });
                  }}
                >
                  {"Add New Card"}
                </button>
              </Flex>
            )}
            {showForm ? (
              <form ref={(c) => (this.form = c)} onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    type="tel"
                    name="number"
                    className="form-control"
                    placeholder="Card Number"
                    pattern="[\d| ]{16,22}"
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                  <small>E.g.: 49..., 51..., 36..., 37...</small>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                    // pattern="[A-Za-z]{3}"
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <input
                      type="tel"
                      name="expiry"
                      className="form-control"
                      placeholder="Valid Thru"
                      pattern="\d\d/\d\d"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="tel"
                      name="cvc"
                      className="form-control"
                      placeholder="CVC"
                      pattern="\d{3,4}"
                      required
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <input type="hidden" name="issuer" value={issuer} />
                <div className="form-actions mt-4">
                  <Flex
                    justifyContent={FlexProps.justifyContent.flexEnd}
                    alignItems={FlexProps.alignItems.flexEnd}
                  >
                    <button className="btn btn-primary btn-sm">
                      Save & PAY
                    </button>
                  </Flex>
                </div>
              </form>
            ) : (
              ""
            )}

            {formData && (
              <div className="App-highlight">
                {formatFormData(formData).map((d, i) => (
                  <div key={i}>{d} danish</div>
                ))}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
