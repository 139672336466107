import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicy = (props) => {
  return (
    <Container className={"mb-4"}>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <h3 className={"m-4"}> Privacy Policy </h3>

          <Container>
            <ol>
              <li>
                {" "}
                Your Information
                <ol className={"m-3"}>
                  <li>
                    {" "}
                    The type of information we collect from you is either
                    personal to you, or is general in nature:
                  </li>
                  <li>
                    {" "}
                    Personal Information: When you register on the Platform as a
                    member, update your information, purchase any goods or
                    services, take part in promotions or send emails to Us, you
                    provide Us with information that We store and process. Such
                    information may include your name, address, phone number,
                    email address, purchase and transaction history, interests,
                    and other such 'Personal Information'.
                  </li>
                  <li>
                    General Information: We also receive more general
                    information from you as a result of your visits to, and use
                    of, the Platform. This general information does not identify
                    you personally, and can include information such as your IP
                    address, the date and time you access the Platform, length
                    of time you spend on the Platform, your browsing history
                    (recorded by the text and graphics files that compose that
                    page), the Internet address of the website from which you
                    linked directly to our Platform, and other such 'General
                    Information'.
                  </li>
                </ol>
              </li>

              <li>
                Collecting and Using Information
                <ol className={"m-3"}>
                  <li>
                    All credit/debit cards details and personally identifiable
                    information will NOT be stored, sold, shared, rented or
                    leased to any third parties.
                  </li>
                  <li>
                    Most of the Platform can be used without giving Us any
                    information. However, certain services may involve
                    collection of information from you. We use this information
                    to provide you with a better service, and in particular to:
                    keep internal records; improve our products, services and
                    the Platform; communicate with you if you have ordered,
                    purchased or participated in anything on the Platform;
                    contact you for market research purposes; and provide you
                    with information about new opportunities, promotions,
                    special offers and other information that We may feel is
                    relevant to you. If you contact Us via the Platform, or via
                    email to the contacts set out on the Platform, We may keep a
                    record of that correspondence.
                  </li>
                  <li>
                    We use General Information to help Us make the Platform more
                    useful to visitors (including to help Us assess content of
                    interest to visitors), and for other purposes such as
                    determining the Platform’s technical design specifications
                    and identifying system performance or problem areas, for
                    tracking activity on the Platform, preparing reports,
                    assessing trends, and otherwise monitoring the way in which
                    the Platform is being used none of which would be in a way
                    that personally identifies any users.
                  </li>
                  <li>
                    Some of the advertisements you see on the Platform are
                    selected and delivered by third parties, such as ad
                    networks, advertising agencies, advertisers, and audience
                    segment providers. These third parties may collect
                    information about you and your online activities, either on
                    the Platform or on other websites, through cookies, web
                    beacons, and other technologies in an effort to understand
                    your interests and deliver to you advertisements that are
                    tailored to your interests. Please remember that We do not
                    have access to, or control over, the information these third
                    parties may collect. The information practices of these
                    third parties are not covered by this privacy policy.
                  </li>
                </ol>
              </li>

              <li>
                Non-Disclosure of Personal Information
                <ol className={"m-3"}>
                  <li>
                    You agree that We may share your Personal Information within
                    Our company. We will not share, sell or rent your Personal
                    Information in any manner to any third parties outside Our
                    company except when we have your permission; or if required
                    to do so by law or any governing authority; or to protect
                    our own legitimate interests.
                  </li>
                </ol>
              </li>

              <li>
                Security
                <ol className={"m-3"}>
                  <li>
                    Keeping your Personal Information secure and preventing
                    unauthorized access is of utmost priority to Us, and We take
                    all steps reasonably necessary to protect your Personal
                    Information against any unauthorised access, use,
                    alteration, disclosure or destruction. Whilst We have put in
                    place physical, electronic and managerial procedures to
                    secure and safeguard your Personal Information, We will not
                    be held responsible for any unauthorised access by third
                    parties and We cannot guarantee that the Personal
                    Information provided by you or that is transmitted via the
                    Platform or by e-mail is totally secure and safe.
                  </li>
                </ol>
              </li>

              <li>
                Use of Cookies
                <ol className={"m-3"}>
                  <li>
                    Like many websites, the Platform uses cookies, which are
                    files that store information on your hard drive. Cookies
                    allow the Platform to identify account holders, track new
                    users, record session information, and generally to optimise
                    your experience. Most browsers accept cookies automatically
                    but if you choose you can disable the cookies from your
                    browser. For online advertising campaigns, cookies are
                    automatically deleted after one (1) month.
                  </li>
                  <li>
                    We recommend that you leave cookies “turned on” so that you
                    can enjoy a better experience on the Platform. You may
                    occasionally get cookies from companies advertising on Our
                    behalf or on behalf of Our subsidiaries. We do not control
                    these cookies, and these cookies are not subject to this
                    Privacy Policy.
                  </li>
                </ol>
              </li>

              <li>
                Use of Google Analytics and Google Tracking Pixels
                <ol className={"m-3"}>
                  <li>
                    This Website uses Google Analytics, a web analytics service
                    of Google Inc. (“Google”). Google Analytics uses cookies
                    and/or text files to analyze website traffic. Information
                    generated by cookies with your details (including your IP
                    address) is transferred to a Google server in the United
                    States of America. Google processes this information to
                    evaluate your use of the Platform, compile reports about the
                    Platform’s activity on Our behalf, and deliver other related
                    services regarding website and Internet use. Google may also
                    share this information with third parties insofar as this is
                    necessary or if these third parties process the information
                    on behalf of Google. Under no circumstances will Google use
                    your IP address in connection with other data supplied by
                    Google.
                  </li>
                  <li>
                    You may disable the cookies from your browser by adjusting
                    your browser settings. However, in this scenario, you may
                    not be able to make full use of all the functions available
                    on the Platform. By using the Platform, you agree to
                    Google’s processing of the type of information discussed in
                    this section in the manner contemplated in this Policy. Us,
                    and our respective suppliers, and any of our shareholders,
                    subsidiaries, parent companies, and any of Our and their
                    officers, directors, managers, members, agents, and
                    employees, are not liable for any direct, indirect,
                    punitive, incidental, special, or consequential damages or
                    loss (including, without limitation, incidental and
                    consequential damages, lost profits, or damages resulting
                    from lost data or business interruption) arising out of, or
                    in way connected with, the use of the type of information
                    discussed in this section.
                  </li>
                </ol>
              </li>

              <li>
                Tracking Pixels
                <ol className={"m-3"}>
                  <li>
                    Facebook and Google tracking pixels are used on the
                    Platform. This means that third parties may use cookies, web
                    beacons, and similar technologies to collect or receive
                    information from the Platform and elsewhere on the internet
                    and use that information to provide measurement services and
                    targeted adverts. If you wish to opt out of the use of these
                    tracking pixels please adjust your browser settings.
                  </li>
                </ol>
              </li>
            </ol>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
