import firebase from "firebase";
import "firebase/auth";
import ErrorType from "../../config/errors/error-types";
import { FirebaseStorage } from "../../config/firebase/firebase-config";

const firestore = firebase.firestore();
firestore.settings({
    timestampsInSnapshots: true,
});

var message = { message: "", error: ErrorType.undefined };

export const Firestore = {
    data: (collection, callback) => {
        firestore
            .collection(collection)
            .get()
            .then((snapshot) => {
                const doc_data = [];
                snapshot.docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    doc_data.push(data)
                });

                callback(doc_data);
            });
    },
    dataWithCondition: (collection, condition = { field: "", filter: "", value: "" }, callback) => {
        const snapshot = firestore
            .collection(collection)
            .where(condition.field, condition.filter, condition.value)
            .get()
            .then((querySnapshot) => {
                const documents = [];
                querySnapshot.forEach((doc) =>
                    documents.push({...doc.data(), Id: doc.id })
                );
                callback(documents);
            });
        if (snapshot.empty) {
            console.log("No matching documents.");
            return;
        }
        snapshot.forEach((doc) => {
            console.log(doc.id, "=>", doc.data());
        });
    },
    limitedData: (collection, limit, callback) => {
        firestore
            .collection(collection)
            .limit(limit)
            .onSnapshot((snapshot) => {
                const documents = [];

                snapshot.forEach((doc) => {
                    var Id = doc.id;
                    for (const key in doc.data()) {
                        documents.push([key, doc.data()[key], Id]);
                    }
                });
                callback(documents);
                console.log(JSON.stringify(documents));
            });
    },
    document: (collection, documentID, callback) => {
        firestore
            .collection(collection)
            .doc(documentID)
            .onSnapshot((snapshot) => {
                callback({ id: snapshot.id, data: snapshot.data() });
                console.log("firestore signle doc ", snapshot.data())
            });
    },
    save: (collection, documentData) => {
        firestore
            .collection(collection)
            .add(documentData)
            .then(function() {
                message = { message: "document added", type: ErrorType.success };
            })
            .catch(function(error) {
                message = { message: error, type: ErrorType.error };
            });
        return message;
    },
    saveWithId: (collection, documentID, documentData) => {

        firestore.doc(`${collection}/${documentID}`).get()
            .then(docSnapshot => {
                if (docSnapshot.exists) {
                    docSnapshot.ref.update(documentData)
                        .then(function() {
                            message = { message: "document update", type: ErrorType.success };
                            console.log("this is signUP here 1 ")
                        })
                        .catch(function(error) {

                            message = { message: error, type: ErrorType.error };
                            console.log("this is signUP here 2")
                        });
                } else {
                    docSnapshot.ref.set(documentData, { merge: true })
                        .then(function() {
                            message = { message: "document update", type: ErrorType.success };
                            console.log("this is signUP here  3")
                        })
                        .catch(function(error) {
                            message = { message: error, type: ErrorType.error };
                            console.log("this is signUP here 4 ")
                        });
                }
            });

        return message;
    },
    update: (collection, documentID, documentData) => {
        firestore
            .collection(collection)
            .doc(documentID)
            .update(documentData)
            .then(function() {
                message = { message: "document update", type: ErrorType.success };
            })
            .catch(function(error) {

                message = { message: error, type: ErrorType.error };
            });
        return message;
    },
    upload: (file, directory, callback) => {
        const uploadTask = FirebaseStorage.ref(`/${directory}/${file.name}`).put(
            file
        );
        uploadTask.on(
            "state_changed",
            (snapShot) => {
                // console.log(snapShot)
            },
            (error) => {
                message = { message: error, type: ErrorType.error };
            },
            () => {
                FirebaseStorage.ref(directory)
                    .child(file.name)
                    .getDownloadURL()
                    .then((fireBaseUrl) => {
                        callback(fireBaseUrl);
                    });
            }
        );
        return message;
    },
    delete: (collection, documentID) => {
        firestore
            .collection(collection)
            .doc(documentID)
            .delete()
            .then(function() {
                message = { message: "document deleted", type: ErrorType.success };
            })
            .catch(function(error) {
                message = { message: error, type: ErrorType.error };
            });
        return message;
    },
};