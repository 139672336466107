import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

const About = (props) => {
  return (
    <Container>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <p>
            About US: Welcome to Ebigwin, your number one source for big
            opportunities. We're dedicated to giving you the very best, with a
            focus on dependability, customer service and uniqueness. We hope you
            enjoy our products as much as we enjoy offering them to you. If you
            have any questions or comments, please don't hesitate to contact us.
            Sincerely Ebigwin Family.
          </p>
          <Container>
            <ul>
              <li>
                <b>What Is EBIGWIN? </b>
                <p>
                  Ebgiwin is a stationery and garment store with a twist. For
                  every purchase made, Ebgiwin gives its customers a
                  complimentary Prize Draw ticket where they can win a luxury
                  prize.
                </p>
              </li>
              <li>
                <b>How can I access Ebgiwin.com? </b>
                <p>
                  Ebgiwin is available on desktop, smart phone, tablet and via
                  our interactive kiosks.{" "}
                </p>
              </li>
              <li>
                <b>Is Ebgiwin available on Android and IOS? </b>
                <p>
                  Yes. Ebgiwin is available on both App Store and Google Play
                  Store.{" "}
                </p>
              </li>
              <li>
                <b>How to find us?</b>
                <p>
                  Ebgiwin HQ is situated in Abu Dhabi United Arab Emirates.{" "}
                </p>
              </li>
              <li>
                <b> What countries does Ebgiwin operate in? </b>
                <p>
                  Ebgiwin is accessible globally. For full details on
                  product/prize collection for international users, please see
                  our ‘Draw Terms & Conditions’.
                </p>
              </li>
            </ul>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
