import React, { useEffect, useState ,useContext} from "react";
import Context from "../../../context/Context";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./assets/stylesheet/index.css";
import Cards from "./components/cards";
import {Link} from "react-router-dom";
import Colors from "../../../config/color";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button"; 
import { generateCoupons } from "../../../helpers/hash";
import Actions from "../actions/form-actions";
const Detail = (props) => {
  // const [detail, setdetail] = useState(
  //   JSON.parse(localStorage.getItem("productDetail"))
  // );
  const { currency, setCurrency, detailCampaign} = useContext(Context);
 const [detail, setdetail] = useState( detailCampaign  );
console.log("detilas compaoasdjflsdfj lsdksd ::: ", detail);
  const [priceState, setPriceState] = useState(detail.product.price);
  const [tempCount, setTempCount] = useState(0);
  const campaign = detail; 
  
  useEffect(() => {
    console.log(" pkr ", priceState);
    setdetail(detailCampaign)
    window.scrollTo(0, 0); 
    // currency checking
    const price = parseInt(detail.product.price);
    Actions.data("currency", function(document){
      var obj = {};
      for (var key in document) {
        obj =  document[key]  ; 
      }   
      if (currency === "AED") {
        setPriceState((price * (parseInt(obj.AED))).toFixed(2));
      } else if (currency === "BHD") {
        setPriceState((price * (parseInt(obj.BHD))).toFixed(2));
      } else if (currency === "INR") {
        setPriceState((price * (parseInt(obj.INR))).toFixed(2));
      } else if (currency === "KWD") {
        setPriceState((price * (parseInt(obj.KWD))).toFixed(2));
      } else if (currency === "OMR") {
        setPriceState((price * (parseInt(obj.OMR))).toFixed(2));
      } else if (currency === "SAR") {
        setPriceState((price * (parseInt(obj.SAR))).toFixed(2));
      }
    }) 

    const tempCarts =
      localStorage.getItem("cart") === undefined ||
      localStorage.getItem("cart") === "undefined" ||
      localStorage.getItem("cart") === null
        ? {}
        : JSON.parse(localStorage.getItem("cart"));

    if (tempCarts === {}) {
      setTempCount(0);
    } else if (tempCarts[campaign.id]?.count > 0) {
      setTempCount(tempCarts[campaign.id]?.count);
    }
  }, []);

  // const handleChangeCounter = (e) => {
  //   e.target.value === "+"
  //     ? setTempCount(tempCount + 1)
  //     : tempCount - 1 >= 0
  //     ? setTempCount(tempCount - 1)
  //     : setTempCount(0)
  //      handleAddtoCard()  
  // } 
  const handleChangeCounter = (e) => {
    const tempThreshold =campaign.threshold
    if (e.target.value === "+") {
      if(tempCount + 1 <= parseInt(tempThreshold)) {
        setTempCount(tempCount + 1);
      }
      else{
        setTempCount(parseInt(tempThreshold)) 
      }
    } else { 
      if( tempCount - 1 >= 0)  {
        setTempCount(tempCount - 1) 
      }else{
        setTempCount(0);
          handleAddtoCard();
      }
    }
  }
  const handleAddtoCard = () => {
 
    let tempCoupons = [];
    tempCoupons = generateCoupons(tempCount);
    const tempCart =
      localStorage.getItem("cart") === undefined ||
      localStorage.getItem("cart") === "undefined" ||
      localStorage.getItem("cart") === null
        ? {}
        : JSON.parse(localStorage.getItem("cart")); 
    if (tempCount === 0) {
      delete tempCart[campaign.id];
    } else {
      campaign["count"] = tempCount;
      tempCart[campaign.id] = campaign;
      tempCart[campaign.id]["product"]["coupons"] = tempCoupons;
      tempCart[campaign.id]["product"]["price"]=priceState;
      tempCart[campaign.id]["newSoldCount"]  = parseInt(campaign.soldCount)+tempCount;
      tempCart[campaign.id]["soldCount"]  = campaign.soldCount ;
      tempCart[campaign.id]["threshold"]  = campaign.threshold ;
      tempCart[campaign.id]["tempCount"]  =tempCount;
      // tempCart[0].product.price=priceState
    }
    localStorage.setItem("cart", JSON.stringify(tempCart));

   // props.onCartUpdate(true);
  };


  return (
    <>
      <Container className="p-2 d-flex justify-content-center align-item-center">
        <Row className="mt-4">
          <div className="m-4">
            <div className="dealcard-upper d-flex">
            <Link to="/">   <i  className="fa fa-arrow-circle-left back-arrow" aria-hidden="true"></i> </Link>
              <div className={"p-2"} style={{ flex: "4" }}>
                <img
                  className="detail-prize-image detail-image"
                  src={detail.product.image}
                  alt="product"
                  style={{ width: "50%" }}
                />
                <span className="product-prize badge sm" style={{ backgroundColor: Colors.primary  }}>{priceState} {currency}</span>
                <div
                  className=" d-flex align-items-center"
                  style={{ position: "absolute", right: "24px", top: "12px" }}
                ></div>
              </div>
              <div className="d-flex flex-column" style={{ flex: "6" }}>
              <h2 >Compaign :  {detail.title}</h2>
                <h4>{detail.title} </h4> 
                <p className="detail-text">{detail.product.description}</p>
                <div className="progress" style= { { borderRadius: "16px", height: "8px "}}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={campaign.soldCount}
                  aria-valuemin="0"
                  aria-valuemax={campaign.threshold}
                  style={{
                    width:
                      (
                        ((parseInt(campaign.soldCount)+tempCount) /
                          parseInt(campaign.threshold)) *
                        100
                      ).toFixed(0) + "%",
                    backgroundColor: Colors.primary,
                    borderRadius: "16px",
                    height: "8px ",
                  }}
                ></div>
                </div>
                <p> 
                  {detail.soldCount}/{detail.threshold}
                </p>
                <div className="meter-btns">
                <ButtonGroup className="mb-2">
                  <Button
                onClick={handleChangeCounter}
                    value="+"
                    className="btn-meter-plus"
                  >
                    +
                  </Button>
                  <span className="btn-meter-value">
                    
                     {tempCount} 
                  
                     </span>
                  <Button
                  onClick={handleChangeCounter}
                    value="-"
                    className="btn-meter-minus"
                  >
                    -
                  </Button>
                  <Button
                      onClick={handleAddtoCard}
                    className="btn-meter-add-to-cart"
                    variant="primary"
                  >
                    Add to Cart
                  </Button>{" "}
                </ButtonGroup>
              </div>
              </div>
            </div>
            <div
              style={{ backgroundColor: Colors.primary }}
              className="d-flex dealcard-lower"
            >
              <div style={{ flex: 4 }}>
                <img
                  className="detail-prize-image  p-2 detail-image "
                  src={detail.prize.image}
                  alt="prize"
                  style={{ width: "50%" }}
                />
              </div>
              <div className="d-flex flex-column" style={{ flex: 6 }}>
                <h4 style={{ color: "#ffffff" }}>{detail.prize.title}</h4>
                <span
                  style={{ color: "aqua" }}
                  className="detail-text mb-3"
                >
                  {detail.product.description}
                </span>
              </div>
            </div>
          </div>
        </Row>
      </Container> 
    </>
  );
};
export default Detail;

// id: "EUEWwEG4etyBXD2eNr1k"
// prize: {description: "Lorem ipsum dolor sit amet, consectetur adipiscing…t tellus sagittis, viverra est a, bibendum metus.", image: "https://firebasestorage.googleapis.com/v0/b/ebigwi…=media&token=e9aa4c60-7a20-42d1-a88c-6fe039d5dbc8", title: "Reward Title"}
// product: {title: "Bottle", description: "Lorem ipsum dolor sit amet, consectetur adipiscing… facilisis justo mollis, auctor consequat urna.  ", price: 20, image: "https://firebasestorage.googleapis.com/v0/b/ebigwi…=media&token=39273fce-1778-4ac5-9a68-e01f23598a4c"}
// soldCount: 0
// status: "active"
// threshold: 90
// title: "Campaign 0"
// __proto__: Object

// <section className="banner spad">
// <div className="container">
//   <div className="row">
//     <div className="col-lg-7 offset-lg-4">
//       <div className="banner__item">
//         <div className="banner__item__pic">
//           <img src={detail.prize.image} alt="" />
//         </div>
//         <div className="banner__item__text">
// <h2>{detail.prize.title}</h2>
//           <h4 >  {detail.prize.description}</h4>
//         </div>
//       </div>
//     </div>
//     <div className="col-lg-5">
//       <div className="banner__item banner__item--middle">
//         <div className="banner__item__pic">
//           <img src={detail.product.image} alt="" />
//         </div>
//         <div className="banner__item__text">
// <h2> {detail.product.title}</h2>
// <h3> {detail.product.price} $</h3>
// <h5>{detail.product.description}</h5>
//         </div>
//       </div>
//     </div>
//     <div className="col-lg-7">
//       {/* <div className="banner__item banner__item--last">
//         <div className="banner__item__pic">

//         </div>
//         <div className="banner__item__text">
//           <h2>Shoes Spring 2030</h2>
//           <a href="#">{detail.product.description}</a>
//         </div>
//       </div> */}
//     </div>
//   </div>
// </div>
// </section>
