import React, { useState,useContext } from "react";
// import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./assets/stylesheet/setting.css";
import { Redirect } from "react-router-dom";
import { Firestore } from "../../../dao/firebase/firebase-firestore";
import { Auth } from "../../../dao/auth/auth";
import { Button } from "react-bootstrap";
import Colors from "../../../config/color";
import { auto } from "@popperjs/core";
import CountryCodes from "../../auth/components/country-codes";
import Context from "../../../context/Context";
const Setting = () => {
  const [alertMsg, setAlertMsg] = useState("");
  var userProfile = (localStorage.getItem("user") == undefined)? {}:JSON.parse(localStorage.getItem("user"));
  const [settingupdate, setSettingUpdate] = useState(false);
  const [redirectToIndex, setRedirectToIndex] = useState(false);
  const { setHideCurrencyBar } = useContext(Context); 
  setHideCurrencyBar(false)
  const handleChange = (e) => {
    userProfile[e.target.name] = e.target.value;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(userProfile).length > 4) {
      Firestore.saveWithId("users", userProfile.uid, userProfile);
      localStorage.setItem("user", JSON.stringify(userProfile));
      setAlertMsg("information Updated");
      setSettingUpdate(true);
      setTimeout(() => {
        setSettingUpdate(false);
        setRedirectToIndex(true);
      }, 1500);
    }
  };
  const handleResetPassword = () => {
    if (userProfile.email !== "") {
      Auth.resetpassword(userProfile.email);
      setAlertMsg("Email Sent Successfully");
      setSettingUpdate(true);
      setTimeout(() => {
        setSettingUpdate(false);
        setRedirectToIndex(true);
      }, 1500);
    }
  };
  return (
    <div className={"mb-4"} style={{ background: "#f3f2ee", height: "100vh" }}>
      {settingupdate ? (
        <div style={{ height: "107vh" }} className="thankyou-container">
          <div className="thankyou-box">
            <h1 className="thankyou-text">{alertMsg} </h1>
          </div>
        </div>
      ) : (
        ""
      )}
      {redirectToIndex ? <Redirect to="/" /> : ""}
      <Form className="p-2" onSubmit={handleSubmit}>
        <Row className="mt-4 d-flex align-items-center justify-content-center p-0 m-0 h-100">
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-center"
          >
            <Row className="setting-container">
              <Col style={{background: Colors.primary , borderRadius: "20px 20px 0px 0px"}} md={12} className="">
                <h2  style={{color: "white"  }} className=" p-2 text-center">Setting</h2>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    defaultValue={userProfile.firstName}
                    className="auth-input"
                    placeholder="First Name"
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    defaultValue={userProfile.lastName}
                    className="auth-input"
                    placeholder="Last Name"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Control
                    type="email"
                    name="email"
                    defaultValue={userProfile.email}
                    onChange={handleChange}
                    className="auth-input"
                    placeholder="email"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <CountryCodes onChange={handleChange} />
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="mobileNo"
                    className="auth-input"
                    defaultValue={userProfile.mobileNo}
                    onChange={handleChange}
                    placeholder="Mobile No"
                    style={{ marginTop: "unset" }}
                  />
                </Form.Group>
              </Col>

              <div
                className={"d-flex p-4"}
                style={{
                  justifyContent: "flex-end",
                  marginLeft: auto,
                  marginRight: 0,
                }}
              >
                <Button
                  className={"mr-2"}
                  type={"submit"}
                  variant={"default"}
                  style={{ backgroundColor: Colors.primary, color: "#fff" }}
                >
                  {"Update Profile"}
                </Button>
                <Button
                  className={"ml-2"}
                  onClick={handleResetPassword}
                  variant={"default"}
                  style={{ backgroundColor: Colors.primary, color: "#fff" }}
                >
                  {"Reset Password"}
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default Setting;
