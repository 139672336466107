 import ErrorType from "../../../config/errors/error-types";
 import { Firestore } from "../../../dao/firebase/firebase-firestore";
 import { validate } from "../../../helpers/validation/form-validation";
 var message = {};
 const LoginActions = {
     data: (collection, callback) => {
         return Firestore.data(collection, callback);
     },
     document: (collection, documentID, callback) => {
         return Firestore.document(collection, documentID, callback);
     },
     save: (collection, documentData) => {
         return Firestore.save(collection, documentData);
     },
     update: (collection, documentID, documentData) => {

         return Firestore.update(collection, documentID, documentData);
     },

     delete: (documentID, callback) => {
         return Firestore.delete("articles", documentID)
     },

     uploadImage: (e, callback) => {
         const image = e.target.files[0];
         try {
             Firestore.upload(image, '', callback);
             message = { message: "file has been upload successfully", type: ErrorType.success }
         } catch (error) {
             console.log(error);
             message = { message: error, type: ErrorType.failure }
         }
         return message;
     },
     validate: (fields) => {
         for (const field in fields) {
             if (!validate(fields[field])) {
                 return false
             }
         }
         return true
     },
     showAlert: (msg, setAlertMsg, setShowAlert) => {
         setAlertMsg(msg)
         setShowAlert(true)
         setTimeout(() => {
             setAlertMsg("")
             setShowAlert(false)
         }, 2000);
     },
     submitForm: (e) => {
         e.preventDefault();
         const data = new FormData(e.target);
         const temp = {
             prize: {
                 description: "",
                 image: "",
                 title: "",
             },
             product: {
                 description: "",
                 image: "",
                 price: "",
                 title: "",
             },
             soldCount: "",
             threshold: "",
             title: "",
         };
         for (var key of data.keys()) {
             const input_name = key.split(".");
             if (input_name.length > 1) {
                 const entity = input_name[0];
                 const prop = input_name[1];
                 temp[entity][prop] = data.get(key);
             } else {
                 const prop = input_name[0];
                 temp[prop] = data.get(key);
             }
         }
         return temp;
     }
 }

 export default LoginActions;