import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import "./assets/stylesheet/hero.css";
import Cards from "./components/cards";
import Colors from "../../../config/color";

  
const Hero = () => {
  return (
    <section >
      <div style={{backgroundImage: `linear-gradient(to right bottom, ${Colors.secondary},${Colors.primary})`}} className="hero-container ">
      <Container className=" ">
        <Row className=" ">
          <Col lg={7} className="hero-left">
            <div className="hero-heading-top">
            <p>   <span  className="card__heading-span--1"> Download Now  </span></p>
            </div>
            <div className="hero-heading-bottom">
            <p>   <span className="card__heading-span--2"> And Start Shopping </span></p>
            </div>
            <div style={{color:Colors.onPrimary}} className="hero-sub-heading">
              <p>EbigWin is platform where you can buy millions of product . As online shopping is increasing day by day , so its a golden chance to buy products on your finger tip</p>
            </div> 
          </Col>
          <Col lg={5} className="hero-right">
          <img src="./assets/img/mobile.png" alt="Appnova Screen" className="img-responsive  mobile-image" data-wow-delay="0.2s" data-wow-duration="1s"/>
          <img src="./assets/img/playstore.png" alt="Appnova Screen" className="img-responsive  playstore-image" data-wow-delay="0.2s" data-wow-duration="1s"/>
          <img src="./assets/img/applestore.png" alt="Appnova Screen" className="img-responsive  applestore-image" data-wow-delay="0.2s" data-wow-duration="1s"/>
          </Col>
        </Row>
      </Container>
      </div>
    </section>
  );
};
export default Hero;
