import React, { useContext } from "react";
import State from "./context/State";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { firebaseAuth } from "./provider/authprovider";
import Navbar from "./_partials/navbar";
import Footer from "./_partials/footer";
import Login from "./components/auth/auth";
import Checkout from "./components/pages/checkout/checkout";
import Index from "./components/pages/index/index";
import Cart from "./components/pages/cart/cart";
import Setting from "./components/pages/setting/setting";
import Detail from "./components/pages/detail/detail";
import { Firestore } from "./dao/firebase/firebase-firestore";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import About from "./components/pages/profile/about"; 
import FAQ from "./components/pages/profile/faq";
import Charity from "./components/pages/profile/charity";
import Terms from "./components/pages/profile/terms";
import UserAgreement from "./components/pages/profile/user-agreement";
import PrivacyPolicy from "./components/pages/profile/privacy-policy";

const App = () => {
  const { token, setToken } = useContext(firebaseAuth);
  setToken(localStorage.getItem("token"));
  Firestore.data("colors", function (dbColors) {
    // console.log("this is dbColors ",  (dbColors[0].['primary']))
    localStorage.setItem("colors", JSON.stringify(dbColors));
  });
  const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx", {
    apiVersion: "2020-08-27",
  }); 
  return (
    
      <State>
        <Switch>
          <Router>
            <>
              <Navbar />
              <div style={{ minHeight: "100vh" }}>
                <Route exact path="/" children={<Index />} />
                <Route path="/login" children={<Login />} />
                <Route path="/cart" children={<Cart />} />
                <Route path="/about" children={<About />} />
                <Route path="/faq" children={<FAQ />} />
                <Route path="/charity" children={<Charity />} />
                <Route path="/terms" children={<Terms />} />
                <Route path="/agreement" children={<UserAgreement />} />
                <Route path="/policy" children={<PrivacyPolicy />} />
                
                {token === null ? (
                  ""
                ) : (
                  <>
                    <Route path="/checkout" children={<Checkout />} />
                  </>
                )}
                {/* <Route path="/winner" children={<Winner />} /> */}
                <Route path="/setting" children={<Setting />} />
                <Route path="/detail" children={<Detail />} />
              </div>
              <Footer />
            </>
          </Router>
        </Switch>
      </State>
  
  );
};

export default App;

// {token === null ? <Route exact path="/" children={<Login />} /> : ""}
// {/* uncomment this line then change the below statment by replaceing the span from end to start*/}
// {token === null ? (
//   <Redirect to="/"></Redirect>
// ) : (
//   <>
//     <Navbar />
//     <div style={{ minHeight: "100vh" }}>
//       <Route exact path="/" children={<Index />} />
//       <Route path="/cart" children={<Cart />} />
//       <Route path="/checkout" children={<Checkout />} />
//       <Route path="/winner" children={<Winner />} />
//       <Route path="/setting" children={<Setting />} />
//       <Route path="/detail" children={<Detail />} />
//     </div>

//     <Footer />
//   </>
// )}
