import React, { useState, useContext, useEffect } from "react";
import Context from "../../../../context/Context";
import Colors from "../../../../config/color";
import { generateCoupons } from "../../../../helpers/hash";
import { Link } from "react-router-dom";
import "../assets/stylesheet/index.css";
import Flex, { FlexProps } from "../../../flex-box/flex";
import { Badge } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Actions from "../../actions/form-actions";
const Cards = (props) => {
  const [cartCount, setCartCount] = useState(0);
  const [count, setCount] = useState(0);
  const [tempCount, setTempCount] = useState(0);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [soldCount, setSoldCount] = useState(0);
  const [] = useState({});
  const campaign = props.data;
  const [priceState, setPriceState] = useState(props.data.product.price);
  const {
    currency,
    setCurrency,
    setDetailCampaign,
    setHideCurrencyBar,
    detailCampaign,
    checked
  } = useContext(Context);

  setHideCurrencyBar(false); 
  useEffect(() => {
    // currency checking
    const price = parseInt(props.data.product.price);

    Actions.data("currency", function(document){
      var obj = {};
      for (var key in document) {
        obj =  document[key]  ; 
      }   
      if (currency === "AED") {
        setPriceState((price * (parseFloat(obj.AED))).toFixed(2));
      } else if (currency === "BHD") {
        setPriceState((price * (parseFloat(obj.BHD))).toFixed(2));
      } else if (currency === "INR") {
        setPriceState((price * (parseFloat(obj.INR))).toFixed(2));
      } else if (currency === "KWD") {
        setPriceState((price * (parseFloat(obj.KWD))).toFixed(2));
      } else if (currency === "OMR") {
        setPriceState((price * (parseFloat(obj.OMR))).toFixed(2));
      } else if (currency === "SAR") {
        setPriceState((price * (parseFloat(obj.SAR))).toFixed(2));
      }
    }) 
    const tempCarts =
      localStorage.getItem("cart") === undefined ||
      localStorage.getItem("cart") === "undefined" ||
      localStorage.getItem("cart") === null
        ? {}
        : JSON.parse(localStorage.getItem("cart"));

    if (tempCarts === {}) {
      setTempCount(0);
    } else if (tempCarts[campaign.id]?.count > 0) {
      setTempCount(tempCarts[campaign.id]?.count);
    }
  }, []);

  // const handleCounter = (_count) => {
  //   const tempCount = parseInt(cartCount) + 1;
  //   // setCount((state) =>+ 1);
  //   setCount(tempCount);
  //   setCartCount(parseInt(tempCount));
  //   let tempCoupons = [];
  //   tempCoupons = generateCoupons(tempCount);
  //   const tempCart =
  //     localStorage.getItem("cart") === undefined ||
  //     localStorage.getItem("cart") === "undefined" ||
  //     localStorage.getItem("cart") === null
  //       ? {}
  //       : JSON.parse(localStorage.getItem("cart"));
  //   campaign["count"] = tempCount;
  //   tempCart[campaign.id] = campaign;
  //   tempCart[campaign.id]["product"]["coupons"] = tempCoupons;
  //   // tempCart[campaign.product.price]=priceSfitate
  //   // tempCart[0].product.price=priceState
  //   localStorage.setItem("cart", JSON.stringify(tempCart));
  //   props.onCartUpdate(true);
  // };

  const handleChangeCounter = (e) => {
    const tempThreshold = campaign.threshold;
    if (e.target.value === "+") {
      if (tempCount + 1 <= parseInt(tempThreshold)) {
        setTempCount(tempCount + 1);
      } else {
        setTempCount(parseInt(tempThreshold));
      }
    } else {
      if (tempCount - 1 >= 0) {
        setTempCount(tempCount - 1);
      } else {
        setTempCount(0);
        handleAddtoCard();
      }
    }
    // e.target.value === "+"
    //   ? (tempCount + 1 <= parseInt(campaign.threshold)
    //     ? setTempCount(tempCount + 1)
    //     : setTempCount(parseInt(campaign.threshold)))
    //   : tempCount - 1 >= 0
    //   ? setTempCount(tempCount - 1)
    //   : setTempCount(0);  handleAddtoCard();
  };

  const handleAddtoCard = () => {
    let tempCoupons = [];  
    
    if(checked){
      tempCoupons = campaign.flash?generateCoupons(tempCount*4):generateCoupons(tempCount*2) 
    }
    else{
    tempCoupons = campaign.flash?generateCoupons(tempCount*2):generateCoupons(tempCount) 
    }

    const tempCart =
      localStorage.getItem("cart") === undefined ||
      localStorage.getItem("cart") === "undefined" ||
      localStorage.getItem("cart") === null
        ? {}
        : JSON.parse(localStorage.getItem("cart"));
    if (tempCount === 0) {
      delete tempCart[campaign.id];
    } else {
      campaign["count"] = tempCount;
      tempCart[campaign.id] = campaign;
      tempCart[campaign.id]["product"]["coupons"] = tempCoupons;
      tempCart[campaign.id]["product"]["price"] = priceState;
      tempCart[campaign.id]["newSoldCount"] =
        parseInt(campaign.soldCount) + tempCount;
      tempCart[campaign.id]["soldCount"] = campaign.soldCount;
      tempCart[campaign.id]["threshold"] = campaign.threshold;
      tempCart[campaign.id]["tempCount"] = tempCount;
      // tempCart[0].product.price=priceState
    }
    localStorage.setItem("cart", JSON.stringify(tempCart));

    props.onCartUpdate(true);
  };

  return (
    <>
      {showAlert ? (
        <div
          style={{ backgroundColor: "red", color: "white", width: "100%" }}
          className="alert alert-danger d-flex align-self-center justify-content-center  "
          role="alert"
        >
          <strong>{alertMsg} </strong>
        </div>
      ) : (
        ""
      )}
      {campaign !== undefined ? (
        <div className="card-box">
          <div className="dealcard-upper d-flex">
            <div className={"p-2"} style={{ flex: "4" }}>
              <img
                className="product-image"
                src={campaign.product.image}
                alt="product"
              />
              <Badge
                className={"product-prize"}
                variant={"lg"}
                style={{ backgroundColor: Colors.primary }}
              >
                {priceState} {currency}
              </Badge>
              <div
                className=" d-flex align-items-center"
                style={{ position: "absolute", right: "24px", top: "12px" }}
              >
                {/* <div className="meter d-flex">
                  <button
                    className={"sm border-0"}
                    style={{ backgroundColor: "transparent" }}
                    onClick={handleCounter}
                  >
                    <span className={"fa fa-plus"}></span>
                  </button>
                  <div className="mb-2 mt-2">
                    <span className={"text-center"}>{count}</span>
                  </div>
                  <button
                    onClick={() => {
                      const tempCart =
                        localStorage.getItem("cart") === undefined ||
                        localStorage.getItem("cart") === "undefined" ||
                        localStorage.getItem("cart") === null
                          ? {}
                          : JSON.parse(localStorage.getItem("cart"));
                      if (tempCart[campaign.id]?.count > 0) {
                        let tempCoupons = [];
                        const tempCount =
                          cartCount - 1 >= 0 ? cartCount - 1 : 0;
                        setCartCount(tempCount);
                        campaign["count"] = tempCount;

                        tempCoupons = generateCoupons(tempCount);

                        tempCart[campaign.id]["coupons"] = tempCoupons;

                        tempCart[campaign.id] = campaign;
                        localStorage.setItem("cart", JSON.stringify(tempCart));
                        props.onCartUpdate(false);
                      } else {
                        const tempCart =
                          localStorage.getItem("cart") === undefined ||
                          localStorage.getItem("cart") === "undefined" ||
                          localStorage.getItem("cart") === null
                            ? {}
                            : JSON.parse(localStorage.getItem("cart"));
                        delete tempCart[campaign.id];
                        localStorage.setItem("cart", JSON.stringify(tempCart));
                        props.onCartUpdate(true);
                      }
                    }}
                    className={"sm border-0"}
                 
                 
                 
                 
                    style={{ backgroundColor: "transparent" }}
                  >
                    <span className={"fa fa-minus"}></span>
                  </button>
                </div> */}
              </div>
            </div>
            <div className="d-flex flex-column" style={{ flex: "6" }}>
              <h4>{campaign.title}<span style={{color:Colors.primary}} className="flash-deal-text">{campaign.flash?"Flash Deal(Get 2X coupons)":""}</span> </h4>
              <p>{campaign.product.description.substr(0, 60)}</p>
              <div
                className="progress"
                style={{ borderRadius: "16px", height: "8px " }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={campaign.soldCount}
                  aria-valuemin="0"
                  aria-valuemax={campaign.threshold}
                  style={{
                    width:
                      (
                        ((parseInt(campaign.soldCount) + tempCount) /
                          parseInt(campaign.threshold)) *
                        100
                      ).toFixed(0) + "%",
                    backgroundColor: Colors.primary,
                    borderRadius: "16px",
                    height: "8px ",
                  }}
                ></div>
              </div>
              <p>
                {campaign.soldCount}/{campaign.threshold}
              </p>

              <div className="meter-btns">
                <ButtonGroup className="mb-2">
                  <Button
                    onClick={handleChangeCounter}
                    value="+"
                    className="btn-meter-plus"
                  >
                    +
                  </Button>
                  <span className="btn-meter-value">{tempCount} </span>
                  <Button
                    onClick={handleChangeCounter}
                    value="-"
                    className="btn-meter-minus"
                  >
                    -
                  </Button>
                  <Button
                    onClick={handleAddtoCard}
                    className="btn-meter-add-to-cart"
                    variant="primary"
                  >
                    Add to Cart
                  </Button>{" "}
                </ButtonGroup>
              </div>
            </div>
          </div>

          {/* <div
            style={{ backgroundColor: Colors.primary }}
            className="d-flex dealcard-lower"
          >
            <div style={{ flex: 4 }}>
              <img
                className="prize-image p-2"
                src={campaign.prize.image}
                alt="prize"
              />
            </div>
            <div className="d-flex flex-column" style={{ flex: 6 }}>
              <p style={{ color: Colors.onPrimary }}>{campaign.prize.title}</p>
              <span style={{ color: Colors.onPrimary }} className="mb-3">
                {campaign.product.description.substr(0, 60)}
              </span>
              <div>
                <Flex justifyContent={FlexProps.justifyContent.flexEnd}>
                  <Link
                    className="auth-verify-btn"
                    style={{
                      width: "fit-content",
                      backgroundColor: Colors.primary,
                    }}
                    onClick={() => (
                      localStorage.setItem(
                        "productDetail",
                        JSON.stringify(campaign)
                      ),
                      setDetailCampaign(props.data),
                      setHideCurrencyBar(true)
                    )}
                     to={"/detail"}
                  >
                    {"View Details"}
                  </Link>
                </Flex>
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Cards;
